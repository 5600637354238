import React, { useEffect, useState } from "react";
import TypeAnimation from "react-type-animation";

export default function Anomaly() {
  const intro =
    "https://storage.googleapis.com/mw_content/Videos/AnomalyIntroCompressed.mp4";
  const loopVideo =
    "https://storage.googleapis.com/mw_content/Videos/AnomalyLoopCompressed.mp4";
  const [loop, setLoop] = useState(false);
  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  useEffect(() => {
    const introVideo = document.getElementById("anomalyIntro");
    const loopVideo = document.getElementById("anomalyLoop");
    document.body.style.backgroundColor = "black";
    const mobileTimer = () => setTimeout(() => setFullScreen(true), 14300);
    const timer1 = () => setTimeout(() => setShowTwo(true), 3000);
    const buttonTimer = () => setTimeout(() => setShowButtons(true), 10000);
    const swapVideos = () => {
      setLoop(true);
      setShowOne(true);
      timer1();
      buttonTimer();
      loopVideo.play();
    };
    introVideo.addEventListener("play", mobileTimer, false);
    introVideo.addEventListener("ended", swapVideos, false);
    return () => {
      clearTimeout(mobileTimer);
      clearTimeout(timer1);
      clearTimeout(buttonTimer);
      introVideo.removeEventListener("play", null);
      introVideo.removeEventListener("ended", null);
      document.body.style.backgroundColor = "#EFE0FF";
    };
  });
  const showThreeAndMedium = () => {
    if (!clicked) {
      setClicked(true);
      setShowThree(true);
      const linkEmThere = () =>
        setTimeout(() => {
          window.open(
            "https://medium.com/@MultiversalWalkers/mit-reklaw-the-anomaly-f5979ca5ac92"
          );
          setShowOne(false);
        }, 2500);
      linkEmThere();
    }
  };

  const typingCommonStyling =
    "bg-mw-offwhite rounded-2xl bg-opacity-25 z-20 text-mw-offwhite text-[2rem] mwSmallDesktop:text-[1.4rem] short:text-[1.2rem] mw-bold leading-tight tracking-wide mwMobile:hidden mwTiny:hidden select-none p-4";
  const typingOne = "tHe nAme's rEklaw. mIt rEklaw.";
  const typingTwo =
    "yOu kNow hOw I sTayed aLive aLl tHis tIme? i'Ve gOt nOthing tO lOse. yOu wAnt tO hEar mY lIfe sTory?";
  const typingThree = "lIke yOu hAd a cHoice";

  return (
    <div className="bg-black overflow-hidden w-screen z-[2]">
      <div className="absolute top-[20%] left-[1%] w-[50%]">
        {showOne && (
          <div className="flex flex-col items-center justify-center space-y-5">
            <TypeAnimation
              cursor={false}
              sequence={[typingOne]}
              repeat={1}
              wrapper="h1"
              className={`${typingCommonStyling} w-fit`}
            />
            {showTwo && (
              <div className={`${typingCommonStyling} w-fit`}>
                <TypeAnimation
                  cursor={false}
                  sequence={[typingTwo]}
                  className="w-full"
                  repeat={1}
                  wrapper="h1"
                />
              </div>
            )}
            {showButtons && (
              <div className="mwTiny:hidden mwMobile:hidden flex justify-center items-center space-x-[4rem] mt-10 z-30">
                <button
                  className="bg-mw-offwhite border-solid border-[0.25px] border-mw-black rounded-[9px] text-mw-black text-xl p-4 w-[10rem] text-center clicky"
                  onClick={() => showThreeAndMedium()}
                >
                  Yes
                </button>
                <button
                  className="bg-mw-offwhite border-solid border-[0.25px] border-mw-black rounded-[9px] text-mw-black text-xl p-4 w-[10rem] text-center clicky"
                  onClick={() => showThreeAndMedium()}
                >
                  No
                </button>
              </div>
            )}
            {showThree && (
              <div className={`${typingCommonStyling} w-fit`}>
                <TypeAnimation
                  cursor={false}
                  sequence={[typingThree]}
                  repeat={1}
                  wrapper="h1"
                  className="w-full"
                />
              </div>
            )}
          </div>
        )}
      </div>
      {showOne && (
        <div
          className="hidden mwTiny:flex mwMobile:flex items-center justify-center flex-col z-20 absolute bottom-0 mwTiny:bottom-[10%] mwMobile:bottom-[10%] mwTiny:bg-opacity-25 
        mwMobile:bg-opacity-25 short:bottom-[5%] shorter:bottom-[5%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-[70%] 
        mwTiny:w-[90%] text-mw-offwhite text-[2rem] mwSmallDesktop:text-[1.4rem] mwTiny:text-[1.5rem] space-y-4"
        >
          <div
            className=" 
             mwMobile:p-2 mwTiny:p-2 short:p-2 shorter:p-2 shorter:text-[1.4rem] items-center justify-center short:text-[1.4rem] mw-bold leading-tight
             tracking-wide bg-black rounded-2xl bg-opacity-10 p-3"
          >
            <TypeAnimation
              cursor={false}
              sequence={[typingOne]}
              repeat={1}
              wrapper="h1"
            />
          </div>
          <a
            href="https://medium.com/@MultiversalWalkers/mit-reklaw-the-anomaly-f5979ca5ac92"
            target="_blank"
            rel="noreferrer"
            className="bg-black rounded-2xl bg-opacity-10 p-3 w-fit clicky border-[0.25px] border-white border-solid"
          >
            mY lIfe sTory
          </a>
        </div>
      )}
      <video
        autoPlay
        muted
        playsInline
        disablePictureInPicture
        id="anomalyIntro"
        className={`min-w-screen w-screen min-h-screen h-screen transform flex object-cover z-[2] ${
          loop ? "hidden" : "flex"
        } ${
          fullScreen
            ? "object-cover"
            : "mwTiny:object-scale-down mwMobile:object-scale-down"
        }`}
      >
        <source src={intro} alt="Tim Walker -> Mit Reklaw" />
      </video>
      <video
        loop
        muted
        playsInline
        disablePictureInPicture
        preload="true"
        id="anomalyLoop"
        className={`min-w-screen w-screen min-h-screen h-screen transform flex object-cover z-[2] ${
          loop ? "flex" : "hidden"
        }`}
      >
        <source src={loopVideo} alt="Mit Reklaw walking forever" />
      </video>
    </div>
  );
}
