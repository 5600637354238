import React from "react";
import { GoArrowRight, GoArrowLeft } from "react-icons/go";
import { useAppContext } from "../../context/AppContext";

export default function SelectAvatar({ dispatch }) {
  const { nextAvatar, previousAvatar } = useAppContext();
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center w-screen h-screen z-30 mwMobile:w-full">
      <div className="w-[60vw] mwMobile:w-[98%] flex justify-between items-center mwTiny:mt-[30rem] mwTiny:w-[70%]">
        <button
          className="bg-mw-offwhite bg-opacity-50 rounded-full very-clicky"
          onClick={() => previousAvatar()}
        >
          <GoArrowLeft className="text-[10rem] mw4k:text-[25rem] mwTiny:text-[5rem] text-center" />
        </button>
        <button
          className="bg-mw-offwhite bg-opacity-50 rounded-full very-clicky"
          onClick={() => nextAvatar()}
        >
          <GoArrowRight className="text-[10rem] mw4k:text-[25rem] mwTiny:text-[5rem] text-center" />
        </button>
      </div>
    </div>
  );
}
