import React from "react";
import { useAppContext } from "../context/AppContext";

import comingSoonH from "../assets/ComingSoon/MW_Wall-H.png";
import comingSoonV from "../assets/ComingSoon/MW_Wall-V.png";

const ComingSoon = () => {
  const { pathName } = useAppContext();
  const date = pathName === "/multipaper";
  return (
    <div className="flex flex-col items-center justify-center overflow-hidden w-screen h-screen short:scale-100">
      <img
        src={comingSoonH}
        alt="coming soon"
        className="z-0 hidden mwMobile:flex mw:flex mw4k:flex w-screen scale-[111%] object-cover"
      />
      <img
        src={comingSoonV}
        alt="coming soon"
        className="z-0 hidden mwTiny:flex w-screen object-cover scale-[111%]"
      />
      <footer className="overflow-hidden absolute h-[10.8rem] bottom-8 w-screen z-10 mwTiny:bottom-1 mwMobile:bottom-12 short:z-10">
        {date && (
          <div className="footer-content mwTiny:m-0 flex justify-center items-center">
            <h1 className="bg-mw-offwhite text-center border-mw-black rounded-[11px] text-mw-black border-[0.25px] border-solid text-[1.45rem] px-6 py-1">
              Tuesday 21st of June
            </h1>
          </div>
        )}
      </footer>
      <p className="mw-bold absolute bottom-4 text-mw-purple text-center w-full text-[0.51rem] short:bottom-0">
        ©2022 MULTIVERSAL WALKERS. ALL RIGHTS RESERVED.
      </p>
    </div>
  );
};

export default ComingSoon;
