import React from "react";
import twitter from "../../assets/Icons/MW_Twitter-P.svg";
import andre from "../../assets/Multipaper/team/andre.png";
import notSoFungibles from "../../assets/Icons/MW_NSF_Logo_Purple.svg";

export default function AndreNSFSection({ data }) {
  return (
    <section className="mx-auto z-30 w-screen text-mw-purple mwTiny:-mt-2 my-[29.5rem] mwSmallDesktop:my-[14rem] mwMobile:my-0 mwTiny:my-0 mwMobile:pt-[10rem] mwTiny:pt-[6rem] mwTiny:pb-4">
      <div className="flex items-center justify-center flex-col">
        {/* Andre Section */}
        <div className="flex z-10 justify-center mwTiny:w-screen">
          {/* Andre Card */}
          <div className="w-[45%] mwMobile:w-[50%] mwTiny:w-[50%] mwTiny:ml-0 ml-[17rem] mwSmallDesktop:ml-10 mwMobile:ml-10 mwMobile:mt-0 mwSmallDesktop:mt-0 mwTiny:mt-0 mt-[1.55rem]">
            <div className="bg-white rounded-[3rem] mwMobile:rounded-2xl mwTiny:rounded-lg mwSmallDesktop:rounded-xl rotate-left border-solid border-[0.25px] border-mw-black mwTiny:p-[0.2rem] p-[0.8rem] mwTiny:ml-[1.45rem] mwTiny:mt-[0.15rem] w-[71%] mwSmallDesktop:w-[90%] mwMobile:w-[85%] mwTiny:w-[78%]">
              <img
                src={andre}
                alt="andre"
                className="object-cover overlay-item mwTiny:rounded-lg mwSmallDesktop:rounded-xl mwMobile:rounded-2xl rounded-[3rem] bg-mw-light-purple"
              />
            </div>
          </div>
          {/* Andre Text */}
          <div className="flex flex-col w-[55%] mwMobile:-ml-[2rem] mwMobile:w-[50%] mwTiny:w-[50%] mwTiny:mt-3 mwMobile:mt-6 mt-[3.5rem] mwTiny:p-[0.2rem] p-[0.3rem] mwMobile:p-0 z-10">
            <p className="mw-bold mwSmallDesktop:text-[3rem] mwMobile:text-[2.4rem] mwTiny:text-[1.2rem] -tracking-[1.3px] text-[4rem]">
              {data.andre}
            </p>
            <div className="flex flex-col mwTiny:w-[85%] mwMobile:w-[80%] w-[65%] z-10">
              <p className="text-mw-black mwTiny:text-[9px] mwSmallDesktop:text-[1.8rem] mwMobile:text-[1.23rem] text-[2rem] mwTiny:tracking-wide tracking-normal mwTiny:leading-3 mwMobile:leading-[1.6rem] leading-[2.5rem] mw-light">
                {data.andreText}
              </p>
              <a
                href="https://twitter.com/MultiversalWalk/"
                target="_blank"
                rel="noreferrer"
                className="w-full"
              >
                <img
                  src={twitter}
                  alt="twitter Logo"
                  className="mwTiny:w-6 mwMobile:w-14 w-[6rem] mwTiny:mt-[0.4rem] mt-[1.4rem]"
                />
              </a>
            </div>
          </div>
        </div>
        {/* Not So Fungibles Section */}
        <div className="flex z-10 justify-center mwTiny:w-screen mt-[3.5rem] mwTiny:mt-[0.6rem]">
          {/* Not So Fungibles Text */}
          <div className="flex flex-col w-[55%] mwMobile:w-[50%] mwTiny:w-[50%] mwMobile:-mt-6 mwTiny:mt-3 mt-[4.5rem] mwTiny:p-[0.2rem] p-[0rem] z-10 mwMobile:mr-0 mwTiny:mr-0 mwTiny:pr-0 pr-[8rem] mwMobile:pr-0">
            <p className="mw-bold mwSmallDesktop:text-[3rem] mwTiny:text-[1.1rem] mwMobile:text-[2.4rem] text-[4rem] mwTiny:-tracking-[2.4px] -tracking-[5px] mwMobile:-tracking-[3.5px] mwTiny:self-end text-end mwTiny:ml-3">
              {data.notSoFungibles}
              <span className="mw-light mwTiny:text-[1rem] text-[2rem] align-text-top">
                ™
              </span>
            </p>
            <div className="flex flex-col mwTiny:w-[10.7rem] w-[64.8%] mwSmallDesktop:w-[90%] mwMobile:w-[82%] z-10 self-end mwTiny:mt-1 mt-2 mwTiny:pl-[12px]">
              <p className="text-mw-black mwMobile:text-[1.23rem] mwTiny:text-[8.6px] text-[2rem] mwSmallDesktop:text-[1.8rem] mwTiny:tracking-[0.5px] mwMobile:tracking-tighter tracking-normal mwTiny:leading-[11.6px] mwMobile:leading-[1.6rem] leading-[2.4rem] mw-light">
                {data.notSoFungiblesText}
              </p>
              <a
                href="https://twitter.com/notsofungibles"
                target="_blank"
                rel="noreferrer"
                className="w-full mt-8 mwTiny:mt-0"
              >
                <img
                  src={twitter}
                  alt="twitter Logo"
                  className="mwTiny:w-6 mwMobile:w-14 w-[6rem] mwMobile:-mt-6 mwTiny:mt-[0.6rem]"
                />
              </a>
            </div>
          </div>
          {/* Not So Fungibles Card */}
          <div className="w-[45%] mwMobile:w-[50%] mwTiny:w-[50%] mwTiny:ml-0 ml-[3rem] mwTiny:mt-0 mwMobile:-mt-8 mt-[1.55rem]">
            <div className="bg-white rounded-[3rem] mwTiny:rounded-lg mwMobile:rounded-2xl mwSmallDesktop:rounded-xl border-solid border-[0.25px] border-mw-black mwTiny:p-[0.2rem] p-[0.8rem] mwTiny:ml-[0.65rem] mwTiny:mt-[0.65rem] rotate-[10deg] mwTiny:rotate-[9deg] w-[66%] mwSmallDesktop:w-[90%] mwMobile:w-[85%] mwTiny:w-[78%]">
              <img
                src={notSoFungibles}
                alt="not so fungibles logo"
                className="object-cover overlay-item mwTiny:rounded-xl mwMobile:rounded-2xl mwSmallDesktop:rounded-xl rounded-[3rem] bg-mw-light-purple"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
