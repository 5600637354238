import React from "react";
import HeroVideo from "../components/HeroVideo";

import timQuinn from "../assets/Homepage/tim_quinn.gif";
import AnimatedClouds from "../components/AnimatedClouds";

export default function Homepage() {
  return (
    <section className="overflow-hidden bg-mw-offwhite-purple">
      <AnimatedClouds />
      <img
        src={timQuinn}
        alt="tim turning into quinn turning into tim turning into..."
        className="hidden absolute mwTiny:flex w-screen transform h-screen object-cover z-[5] annoyinglyShort:object-contain"
      />
      <HeroVideo />
      <p
        className="text-mw-purple absolute bottom-0 text-center w-full text-xs mb-4
      mwTiny:text-[3.5px] mwTiny:mb-2 z-[5]"
      >
        ©2022 MULTIVERSAL WALKERS. ALL RIGHTS RESERVED.
      </p>
    </section>
  );
}
