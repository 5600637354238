import React from "react";
import HeroVideo from "../components/HeroVideo";

export default function Portal() {
  return (
    <section className="overflow-hidden">
      <div className="group w-[20%] mwTiny:w-[80%] mwMobile:w-[50%] short:w-[40%] h-[30%] short:h-[45%] z-10 absolute top-[38%] mwMobile:top-[40%] short:top-[35%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <p className="text-mw-offwhite opacity-0 mw-bold rounded-xl border-[0.25px] border-solid border-mw-offwhite absolute z-10 group-hover:opacity-100 text-[2rem] short:text-[1.5rem] text-center top-[200%] short:top-[150%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full short:w-[calc(100vw-60%)] transition duration-500 ease-in-out">
          Multiversal travel unavailable
        </p>
      </div>
      <HeroVideo />
    </section>
  );
}
