import React from "react";
import logoStack from "../../assets/Icons/MW_Logo-Icon-Stacked.svg";

export default function MultipaperFooter() {
  return (
    <footer className="mx-auto flex items-center justify-center flex-col w-screen bg-mw-light-purple">
      <h1 className="mw-bold text-mw-purple mwTiny:text-[1.8rem] mwMobile:text-[6vw] text-[4.2rem] text-center w-screen mwTiny:tracking-wide tracking-[5px] mwTiny:-mt-4 -mt-5 z-40">
        KEEP WALKING
      </h1>
      <p className="mw-bold text-mw-purple mwTiny:text-[0.2rem] text-[0.6rem] text-center tracking-tighter mwTiny:mt-1 mt-5">
        ©2022 MULTIVERSAL WALKERS. ALL RIGHTS RESERVED.
      </p>
      <img
        src={logoStack}
        alt="stacked multiversal walkers logo"
        className="mwTiny:w-[1.5rem] mwTiny:mt-3 mwTiny:mb-5 w-[4.5rem] mt-9 mb-[5.3rem] mwSmallDesktop:mb-0"
      />
    </footer>
  );
}
