import React from "react";
import timLeft from "../../assets/Multipaper/tim-left.png";
import timCenter from "../../assets/Multipaper/tim-center.png";
import timRight from "../../assets/Multipaper/tim-right.png";
import timbg from "../../assets/Multipaper/multipaper_bg.png";
import AnimatedClouds from '../AnimatedClouds';

export default function MultipaperHero() {
  return (
    <section className="mx-auto w-screen flex flex-col items-center mw:justify-center z-0 text-mw-purple overflow-x-clip">
      <AnimatedClouds />
      <h1 className="mw-bold z-[10] text-[5.6rem] mwMobile:text-[4rem] mwTiny:text-[1.95rem] leading-snug -tracking-[1px]">
        MULTIPAPER
      </h1>
      <div className="flex relative w-screen z-[8]">
        <img
          src={timLeft}
          alt="tim pointing to the right"
          className="absolute z-[19] -top-[30%] mwMobile:-top-[24%] mwTiny:-top-[26%] left-0 w-[150%]"
        />
        <img
          src={timCenter}
          alt="tim pointing at both other tims"
          className="absolute z-[19] top-[50%] mwMobile:top-[55%] mwTiny:top-[57%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[150%]"
        />
        <img
          src={timRight}
          alt="tim pointing to the left"
          className="absolute z-[19] -top-[30%] mwMobile:-top-[24%] mwTiny:-top-[26%] right-0 w-[150%]"
        />
        <div className="overflow-x-clip">
          <img
            src={timbg}
            alt="background for tims spiderman meme"
            className="w-screen h-[100%] transform flex object-cover z-0 scale-[160%] mwMobile:scale-[160%] mwTiny:scale-[170%] pr-[2.4rem] mwMobile:pr-[1rem] mwTiny:pr-[2.65rem] mwTiny:ml-[2rem] mt-[1.6rem] mwTiny:mt-[1.2rem]"
          />
        </div>
      </div>
    </section>
  );
}
