import React from "react";
import landingStill from "../assets/Homepage/MW_Wall-V_NO-TM.png";
import { useAppContext } from "../context/AppContext";

export default function HeroVideo() {
  const { pathName } = useAppContext();
  const video = () => {
    switch (pathName) {
      case "/":
        return "https://storage.googleapis.com/mw_content/Videos/MW_FrontPage_3.mp4";
      case "/portal":
        return "https://storage.googleapis.com/mw_content/Videos/Portal.mp4";
      case "/mint":
        return "https://storage.googleapis.com/mw_content/Videos/MintPageHoverCompressed.mp4";
      default:
        return "https://storage.googleapis.com/mw_content/Videos/DataCenterWithTimsLooped.mp4";
    }
  };
  return (
    <div className="z-[2]">
      <video
        loop
        autoPlay
        muted
        playsInline
        disablePictureInPicture
        className={`min-w-screen w-screen min-h-screen h-screen transform flex object-cover z-[2] mw:block ${
          pathName === "/"
            ? "mwMobile:object-contain mwSmallDesktop:object-scale-down shorter:object-cover mwTiny:hidden"
            : pathName === "/datacenter"
            ? "mwTiny:hidden mwMobile:hidden"
            : "mwMobile:object-cover mwTiny:block"
        }`}
        preload="true"
        key={pathName}
      >
        <source src={video()} type="video/mp4"></source>
      </video>
      {pathName === "/" && (
        <div
          className={`hidden mwTiny:flex z-[2] justify-center items-center h-screen w-screen ${
            pathName === "/" &&
            "shorter:scale-[80%] annoyinglyShort:scale-[50%]"
          }`}
        >
          <img
            src={landingStill}
            alt="landing placeholder"
            className="z-[2] scale-[135%] -mt-20 h-[24.5rem] overflow-hidden"
          />
        </div>
      )}
      {pathName === "/datacenter" && (
        <div>
          <video
            loop
            autoPlay
            muted
            playsInline
            disablePictureInPicture
            preload="true"
            className="hidden mwTiny:flex mwMobile:flex z-[2] min-w-screen w-screen min-h-screen h-screen transform object-cover"
            key={pathName}
          >
            <source
              src="https://storage.googleapis.com/mw_content/Videos/DataCenterNoTimsLooped.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
      )}
    </div>
  );
}
