import React from "react";
import fishguy from "../../assets/Mint/fishguy.png";

const MintError = ({ error, state }) => {
  const {
    canMint,
    numberMinted,
    saleState,
    minted,
    minting,
    signature,
    aux,
    supply,
  } = state;

  const guidance = () => {
    // !!! SOLD OUT !!!
    if (supply === 5555) {
      return (
        <span>
          All Tims and Quinns have departed on their multiversal adventure! LETS
          F****** WALK!!!!!
        </span>
      );
    }
    // ! Paused
    if (saleState === 0 && numberMinted === 0) {
      return (
        <span>Multilist mint will begin Thursday, July 14, 2022 10:00 AM</span>
      );
    } else if (saleState === 0 && numberMinted > 0) {
      return (
        <span className="underline underline-offset-8 decoration-white text-center">
          Mints have been paused, check Twitter for updates.
        </span>
      );
    }
    // ! Public Sale
    if (numberMinted < 3 && saleState === 1) {
      return (
        <span className="underline underline-offset-8 decoration-white">
          You've already minted {numberMinted}, the maximum number that can be
          minted by your account.
        </span>
      );
    } else if (numberMinted >= 3 && saleState === 1) {
      return (
        <span className="underline underline-offset-8 decoration-white">
          ...Wait a minute! Thank you for max minting your{" "}
          <span className="text-[#FFD700]">Golden Multilist</span> and believing
          in the project! Alright SER! I see your conviction.
          <br />
          <div className="no-underline text-end mt-8">
            -Tim Walker and The NSF Team
          </div>
        </span>
      );
    }
    // ! Multilist Sale
    if ((!signature || aux) && saleState === 2) {
      if (saleState === 2 && !signature) {
        return (
          <span className="underline underline-offset-8 decoration-white">
            You are not on the Multilist.
          </span>
        );
      }
      if (numberMinted < 3) {
        return (
          <span className="underline underline-offset-8 decoration-white">
            You've already minted {numberMinted} during the Multilist Sale.
          </span>
        );
      } else {
        return (
          <span className="underline underline-offset-8 decoration-white">
            ...Wait a minute! Thank you for max minting your{" "}
            <span className="text-[#FFD700]">Golden Multilist</span> and
            believing in the project! Alright SER! I see your conviction.
            <br />
            <div className="no-underline text-end mt-8">
              -Tim Walker and The NSF Team
            </div>
          </span>
        );
      }
    }
  };

  return (
    <>
      {(!canMint || error) && !minted && !minting && (
        <div
          className={`overflow-hidden absolute bottom-[15%] mwSmallDesktop:bottom-[20%] w-[80%] mwMobile:w-[65%] left-[15%] mwMobile:left-[25%] select-none mwSmallDesktop:left-[19%] text-[3rem] mwSmallDesktop:text-[2.5rem] mwMobile:text-[2rem] mwTiny:text-[1rem] z-20 flex justify-start items-center mw-bold text-mw-offwhite chat-bubble ${
            error ? "bg-red-900/80" : "bg-mw-purple/40 dropShadow"
          }`}
        >
          {error && (
            <h1 className="mw:strokedText mw4k:strokedText mwSmallDesktop:strokedText mwMobile:strokedText underline underline-offset-8 decoration-white">
              Error: {error.error ? error.error.message : error.message}
            </h1>
          )}
          {!error && !canMint && !minted && (
            <div className="mw:strokedText mw4k:strokedText mwSmallDesktop:strokedText">
              {guidance()}
            </div>
          )}
        </div>
      )}
      {(!canMint || error) && !minted && !minting && (
        <div className="w-screen h-screen">
          <img
            src={fishguy}
            alt="fish guy"
            className="absolute top-[90%] left-[5%] transform -translate-x-1/2 -translate-y-1/2 h-auto flex-shrink-0 select-none"
          />
        </div>
      )}
    </>
  );
};

export default MintError;
