import React from "react";
import twitter from "../../assets/Icons/MW_Twitter-P.svg";
import one from "../../assets/Multipaper/team/01.png";
import two from "../../assets/Multipaper/team/02.png";
import three from "../../assets/Multipaper/team/03.png";
import four from "../../assets/Multipaper/team/04.png";
import five from "../../assets/Multipaper/team/05.png";
import six from "../../assets/Multipaper/team/06.png";
import eight from "../../assets/Multipaper/team/08.png";
import nine from "../../assets/Multipaper/team/09.png";
import ten from "../../assets/Multipaper/team/10.png";
import eleven from "../../assets/Multipaper/team/11.png";
import thirteen from "../../assets/Multipaper/team/13.png";
import fourteen from "../../assets/Multipaper/team/14.png";
import fifteen from "../../assets/Multipaper/team/15.png";

export default function Team({ team }) {
  const getTeamImage = (name) => {
    switch (name) {
      case "M3TABAUM":
        return eight;
      case "BKY":
        return two;
      case "ALPHAMON":
        return six;
      case "LETICAT":
        return three;
      case "ITSCUZZO":
        return one;
      case "SIRRANDALOT":
        return four;
      case "BLUEFUNGIBLES":
        return thirteen;
      case "YETIHUNTER":
        return ten;
      case "KUMICHO":
        return five;
      case "EAGLE 1":
        return fourteen;
      case "THOMAS":
        return nine;
      case "ALEX":
        return fifteen;
      default:
        return eleven;
    }
  };
  return (
    <section className="mx-auto flex flex-col items-center text-mw-purple w-screen bg-gradient-to-t from-mw-light-purple to-[#FFFFFF]">
      <h2
        className="mw-bold z-10 text-[5.5rem] tracking-normal text-center mt-[4.5rem]
      mwTiny:text-[1.85rem] mwTiny:-mt-2"
      >
        NSF TEAM
      </h2>
      <div
        className="grid grid-cols-3 items-start w-[74%] mwSmallDesktop:w-full mt-[8.4rem] place-items-center
      mwTiny:grid-cols-4 mwTiny:gap-x-0 mwTiny:gap-y-6 mwTiny:mt-[0.8rem] mwTiny:px-7 mwTiny:w-full"
      >
        {team.map((card, i) => (
          <div
            className={`flex flex-col items-center z-10 mb-[3.5rem] w-[100%]
            mwTiny:col-span-2 mwTiny:-mb-[0.3rem] mwTiny:w-full ${
              i === 12 &&
              "mwTiny:col-start-2 mwTiny:col-end-4 mwTiny:justify-self-center col-start-2"
            }`}
            key={card.name}
          >
            <a
              href={card.twitter}
              target="_blank"
              rel="noreferrer"
              className={`relative bg-white rounded-[40px] p-[12px] border-mw-black border-solid border-[0.25px] w-[92%]
            mwMobile:rounded-2xl mwSmallDesktop:rounded-xl mwMobile:p-[6px] mwSmallDesktop:p-[6px]
            mwTiny:rounded-lg mwTiny:p-[4px] mwTiny:w-[88%] ${
              card.twitter === "" && "pointer-events-none cursor-default"
            }`}
            >
              <img
                src={getTeamImage(card.name)}
                alt={card.name + "image"}
                className="bg-mw-light-purple rounded-[40px] mwMobile:rounded-2xl mwSmallDesktop:rounded-xl mwTiny:rounded-none"
              />
              {card.twitter && (
                <div className="absolute w-fit top-[78%] left-5 mwTiny:left-2 mwMobile:left-3 mwSmallDesktop:left-4 mwMobile:top-[10%] mwSmallDesktop:top-[10%] mwTiny:top-[10%]">
                  <img
                    src={twitter}
                    alt="twitter Logo"
                    className="w-[6rem] mwSmallDesktop:w-[3rem] mwTiny:w-[2rem] mwMobile:w-[2.5rem]"
                  />
                </div>
              )}
            </a>
            <p className="mwTiny:text-[13px] mwMobile:text-[1.2rem] mwTiny:mt-[0.6rem] mt-[1rem] mw-bold tracking-wide text-center text-[2.5rem] w-full">
              {card.name}
            </p>
            <p
              className={`text-mw-black tracking-normal leading-snug text-[1.25rem] mwMobile:text-[1rem] text-center
              mwTiny:text-[8.2px] ${
                [3, 5, 8, 10, 12].includes(i)
                  ? "w-[45%] mwSmallDesktop:w-[80%] mwTiny:w-[60%] mwMobile:w-[80%]"
                  : [4, 7, 11].includes(i)
                  ? "w-[60%] mwMobile:w-[85%]"
                  : "w-[85%]"
              }`}
            >
              {card.desc}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
