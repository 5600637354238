import React from "react";
import data from "../data/multipaper.json";

import cloudW from "../assets/Multipaper/MW_Clouds-W.svg";
import cloudLP from "../assets/Multipaper/MW_Clouds-LP.svg";

// Components
import DoritoCards from "../components/multipaper/DoritoCards";
import Crosswalk from "../components/multipaper/Crosswalk";
import Team from "../components/multipaper/Team";
import Roadmap from "../components/multipaper/Roadmap";
import Discord from "../components/multipaper/Discord";
import MultipaperHero from "../components/multipaper/MultipaperHero";
import MultipaperFooter from "../components/multipaper/MultipaperFooter";
import AndreNSFSection from "../components/multipaper/AndreNSFSection";

export default function Multipaper() {
  return (
    <div className="mwTiny:mt-12 mwMobile:mt-[12rem] mt-[12rem] overflow-hidden bg-mw-light-purple">
      {/* Hero */}
      <MultipaperHero />
      {/* Andre and Not So Fungibles Info */}
      <AndreNSFSection data={data} />
      {/* Roadmap */}
      <Roadmap roadmapText={data.roadmapText} />
      {/* Crosswalk */}
      <Crosswalk />
      {/* Cards */}
      <DoritoCards cards={data.cards} />
      {/* Team */}
      <div className="relative z-30 mwTiny:-mt-[3rem] -mt-[9.5rem]">
        <img src={cloudW} alt="white cloud" className="w-screen" />
        <div className="bg-[#FFFFFF] w-screen -mt-1 absolute h-[6px] z-10"></div>
      </div>
      <Team team={data.team} />
      {/* Discord */}
      <div className="relative z-0 mwTiny:-mt-[6.3rem] -mt-[34rem] mwTiny:mb-[5.5rem]">
        <img src={cloudW} alt="light purple cloud" className="w-screen z-10" />
        <div className="w-screen -mt-1 absolute h-[6px] z-10 bg-[#FFFFFF]"></div>
      </div>
      <div className="bg-[#FFFFFF] z-30 w-screen mwTiny:-mt-[6.3rem] mwTiny:h-[100px] h-[550px]"></div>
      <Discord mods={data.mods} />
      {/* Footer */}
      <div className="relative z-30 mwTiny:-mt-20 -mt-[9.2rem] mwMobile:-mt-[6rem] short:-mt-[2.6rem] shorter:-mt-[5rem]">
        <img src={cloudLP} alt="light purple cloud" className="w-screen z-10" />
        <div className="bg-mw-light-purple w-screen -mt-1 absolute h-[6px] mwMobile:h-[8px] z-10"></div>
      </div>
      <MultipaperFooter />
    </div>
  );
}
