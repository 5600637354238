import React from "react";
import dorito from "../../assets/Multipaper/MW_Roadmap_Number Holder.svg";

export default function DoritoCards({ cards }) {
  const getDoritoLocation = (cardIndex) => {
    switch (cardIndex) {
      case 0:
        return "-right-[7%] mwTiny:right-[3.5%] mwMobile:right-[4%] -top-[34%] mwTiny:-top-[40%] mwMobile:-top-[55%]";
      case 1:
        return "-left-[8%] mwTiny:left-[4%] mwMobile:left-[4%] -top-[34%] mwMobile:-top-[40%]";
      case 2:
        return "-right-[7%] mwTiny:right-[4.5%] mwMobile:right-[4%] -top-[24%] mwMobile:-top-[30%] mwTiny:-top-[20%]";
      case 3:
        return "-left-[9%] mwTiny:left-[3%] mwMobile:left-[4%] -top-[28%] mwTiny:-top-[25%]";
      case 4:
        return "-right-[7%] mwTiny:right-[4.5%] mwMobile:right-[4%] -top-[40%] mwMobile:-top-[75%]";
      default:
        return "-left-[8%] mwTiny:left-[3.5%] mwMobile:left-[4%] -top-[40%] mwMobile:-top-[50%]";
    }
  };
  return (
    <section className="mx-auto z-[99] flex items-center justify-center w-screen text-mw-purple mwMobile:mt-[2.5rem] pb-[20rem] mwTiny:pb-[4rem] overflow-x-clip bg-gradient-to-t from-mw-purple to-mw-light-purple">
      <div className="flex flex-col justify-center items-center mt-4 mwMobile:mt-4 mwTiny:w-[78.6%] mwMobile:w-[85%] mwSmallDesktop:w-[85%] w-[63%]">
        {cards.map((card, i) => (
          <div
            className="relative flex flex-col w-full z-10 border-mw-black border-solid border-[0.25px] bg-mw-offwhite
            p-[4.5rem] mt-[1rem] mb-[6rem] rounded-[60px]
            mwMobile:pt-[1.3rem] mwMobile:pb-[1.3rem] mwMobile:px-[2rem] mwMobile:mt-5 mwMobile:mb-9 mwMobile:rounded-2xl
            mwTiny:pt-[0.6rem] mwTiny:pb-[0.6rem] mwTiny:px-[0.7rem] mwTiny:mt-[0.7rem] mwTiny:mb-[0.8rem] mwTiny:rounded-[10px]"
            key={card.header}
          >
            <div
              className={`absolute flex flex-shrink justify-center items-center ${getDoritoLocation(
                i
              )}`}
            >
              <img
                src={dorito}
                alt="number holder"
                className={`w-[22rem] mwMobile:w-[10rem] mwTiny:w-[4.5rem] z-[10] ${
                  i % 2 === 0
                    ? "scale-x-[-1] rotate-[-9.5deg]"
                    : "rotate-[10deg]"
                }`}
              />
              <h1 className="mw-bold mwTiny:text-[2rem] mwMobile:text-[5rem] text-[12rem] text-mw-offwhite absolute z-[15] mwTiny:mt-4 mwMobile:mt-10 mt-24">
                {i + 1}
              </h1>
            </div>
            <p
              className={`w-full mw-bold
              text-[3rem] mwSmallDesktop:text-[2.5rem]
              mwMobile:text-[1.4rem] mwMobile:mt-0 mwMobile:pb-3
              mwTiny:text-[11.5px] mwTiny:-tracking-[0.4px] mwTiny:mt-[0.3rem] ${
                i % 2 === 0
                  ? "text-left mwTiny:ml-[0.8rem] mwMobile:mt-0 mwMobile:ml-[1.2rem]"
                  : "text-right -mr-[1.5rem] mt-[0.8rem] mwMobile:mt-0 mwMobile:mb-6"
              } ${i === 2 && "mwTiny:pt-1 mwMobile:pt-0"}`}
            >
              {card.header}
            </p>
            {card.labText && (
              <h3
                className="text-mw-black
              mt-[4rem] ml-2 -mb-12 leading-tight text-[2rem]
              mwMobile:mt-4 mwMobile:ml-4 mwMobile:-mb-3 mwMobile:leading-tight mwMobile:text-[1.3rem] mwMobile:-tracking-[0.3px] 
              mwTiny:mt-2 mwTiny:text-[9px] mwTiny:w-[100%] mwTiny:tracking-[0.35px] mwTiny:leading-[0.75rem] mwTiny:ml-[0.7rem] mwTiny:-mb-[0.4rem]"
              >
                {card.labText}
              </h3>
            )}
            <h3
              className={`text-mw-black
               ml-[0.5rem] mt-[3rem] tracking-normal leading-[2.4rem] text-[2rem]
               mwMobile:ml-0 mwMobile:mt-2 mwMobile:-tracking-[0.55px] mwMobile:leading-tight mwMobile:text-[1.3rem] mwMobile:w-[100%] 
               mwTiny:mt-0 mwTiny:text-[9px] mwTiny:w-[100%] mwTiny:tracking-[0.40px] mwTiny:leading-[0.75rem] mwTiny:pr-[0.2rem] ${
                 i % 2 === 0
                   ? "text-left mwTiny:ml-[0.65rem] mwTiny:mt-[0.3rem] mwMobile:ml-[1rem] mwMobile:mt-[0.6rem]"
                   : "mwTiny:mt-[7px] mwTiny:ml-[4px] mwMobile:ml-0"
               } ${
                [4, 5].includes(i)
                  ? "pb-[1rem] mwTiny:pb-0 mwMobile:pb-0"
                  : i === 2
                  ? "pb-[3.6rem] mwTiny:pb-0 mwMobile:pb-0"
                  : "pb-[2.8rem] mwTiny:pb-0 mwMobile:pb-0"
              } ${i === 2 && "mwTiny:-mb-1"}`}
            >
              {card.text}
            </h3>
          </div>
        ))}
      </div>
    </section>
  );
}
