import React from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function MintingSpinner({ minting, minted }) {
  return (
    <AnimatePresence>
      {minting && !minted && (
        <motion.img
          key="portalSpinner"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          src="https://storage.googleapis.com/mw_content/Videos/FeatureImage%20(2).gif"
          alt="portal"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-[150%] mwSmallDesktop:scale-125 mwTiny:scale-100 z-10"
        />
      )}
    </AnimatePresence>
  );
}
