import React from "react";
// import crosswalk from "../assets/Multipaper/MW_Roadmap_Crossing Lines.svg";
// import Carousel from "./Carousel";

const Crosswalk = () => {
  const crosswalkTims =
    "https://storage.googleapis.com/mw_content/Videos/InfiniTims.mp4";
  return (
    <section className="mx-auto w-screen overflow-hidden">
      <video
        loop
        autoPlay
        muted
        playsInline
        disablePictureInPicture
        className="min-w-screen w-screen min-h-full h-full transform flex object-cover z-[2] mt-[13rem] mwSmallDesktop:mt-[5rem] mwTiny:mt-[1.5rem] mwMobile:mt-0 mb-[10rem] mwSmallDesktop:mb-[10rem] mwTiny:mb-[1rem] mwMobile:mb-[0rem]"
        preload="true"
      >
        <source src={crosswalkTims} type="video/mp4"></source>
      </video>
    </section>
  );
};

export default Crosswalk;
