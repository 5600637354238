import React, { useState } from "react";
import axios from "axios";
import { ethers } from "ethers";
import types from "../../data/types.json";

export default function MintButton({ state, dispatch, abi }) {
  const [clicked, setClicked] = useState(false);
  const {
    account,
    contract_addr,
    mintAmount,
    maxMintAmount,
    publicPrice,
    multiPrice,
    signature,
    canMint,
    saleState,
  } = state;

  const publicMint = async () => {
    if (window.ethereum) {
      setClicked(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contract_addr, abi, signer);
      try {
        const contractSignature = await axios.get(
          `https://2lu6ia49q0.execute-api.us-east-2.amazonaws.com/sign/${account}/${mintAmount}`
        );
        setClicked(false);
        const resp = await contract.publicMint(
          mintAmount,
          contractSignature.data.signature,
          {
            gasLimit: 150000,
            value: ethers.utils.parseUnits(
              (mintAmount * publicPrice).toString(),
              "ether"
            ),
          }
        );
        dispatch({ type: types.MINTING });
        await resp.wait();
        const justMinted = resp.data && resp.data.length > 73 && resp.data[73];
        dispatch({
          type: types.MINTED,
          payload: {
            canMint: maxMintAmount - justMinted > 0,
            numberMinted: justMinted,
            hash: resp.hash,
            mintAmount: Math.max(0, 2 - justMinted),
          },
        });
      } catch (err) {
        dispatch({ type: types.ERROR, payload: err });
      }
    }
  };

  const multilistMint = async () => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contract_addr, abi, signer);
      const multiMintSignature = signature[mintAmount];
      try {
        const resp = await contract.multilistMint(
          mintAmount,
          multiMintSignature,
          {
            gasLimit: 150000,
            value: ethers.utils.parseUnits(
              (mintAmount * multiPrice).toString(),
              "ether"
            ),
          }
        );
        dispatch({ type: types.MINTING });
        await resp.wait();
        const justMinted = resp.data && resp.data.length > 73 && resp.data[73];
        dispatch({
          type: types.MINTED,
          payload: {
            canMint: false,
            numberMinted: justMinted,
            hash: resp.hash,
            mintAmount: 0,
          },
        });
      } catch (err) {
        dispatch({ type: types.ERROR, payload: err });
      }
    }
  };

  return (
    <div className="bg-mw-purple z-30 h-[9.375rem] mw4k:h-[12rem] mwTiny:h-[6rem] mwSmallDesktop:h-[6.25rem] w-full -mt-1 flex flex-col justify-center items-center bg-opacity-20 backdrop-blur-lg">
      <button
        disabled={!canMint}
        onClick={saleState === 1 ? publicMint : multilistMint}
        className={`z-30 text-center mw-normal bg-mw-offwhite border-solid border-[0.25px] border-mw-black rounded-[0.5rem] text-mw-black text-[2rem] h-[4rem] mw4k:h-[8rem] w-[15rem] mw4k:w-[30rem] clicky ${
          clicked && "animate-pulse"
        }`}
      >
        Mint ({saleState === 1 ? "Public" : "Multi"})
      </button>
    </div>
  );
}
