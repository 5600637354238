import React from "react";
import { Route, Routes } from "react-router-dom";
import "./scss/index.scss";
import "./index.css";
import Navbar from "./components/Navbar";
import HamburgerMenu from "./components/HamburgerMenu";
import Homepage from "./pages/Homepage";
import Multipaper from "./pages/Multipaper";
import Mint from "./pages/Mint";
import Gallery from "./pages/Gallery";
import Portal from "./pages/Portal";
import Datacenter from "./pages/Datacenter";
import Anomaly from "./pages/Anomaly";
import HomepageFooter from "./components/HomepageFooter";

function App() {
  return (
    <>
      <Navbar />
      <HamburgerMenu />
      <main>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/multipaper" element={<Multipaper />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/portal" element={<Portal />} />
          <Route path="/datacenter" element={<Datacenter />} />
          <Route path="/anomaly" element={<Anomaly />} />
        </Routes>
      </main>
      <HomepageFooter />
    </>
  );
}

export default App;
