import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import types from "../../data/types.json";
import etherscan from "../../assets/Mint/etherscan.svg";
import { BsChatFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useAppContext } from "../../context/AppContext";
import MintingSpinner from "./MintingSpinner";

export default function MintConfirmation({ state, dispatch }) {
  const { avatars, currentAvatar } = useAppContext();
  const { minted, minting, numberMinted, hash } = state;
  return (
    <AnimatePresence>
      {minted && (
        <motion.div
          key="speechBubble"
          initial={{ opacity: 0, left: "80%" }}
          animate={{ opacity: 1, left: "50%" }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 1, type: "spring" }}
          className="overflow-hidden absolute flex justify-center text-white items-center top-[45%] mwTiny:top-[35%] mwTiny:h-fit left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 pointer will-change"
        >
          <BsChatFill
            className="text-[40rem] mw4k:text-[60rem] mwSmallDesktop:text-[30rem] mwMobile:text-[35rem] mwTiny:text-[20rem] mwTiny:h-[40rem] -scale-x-100 text-[#0b93f6] mwTiny:rotate-[35deg]"
            key="speechBubbleSVG"
          />

          <div className="w-[31.25rem] h-[18.75rem] mwTiny:h-[12rem] select-none mw4k:w-[45rem] mw4k:h-[30rem] mwTiny:w-[15rem] speech-bubble absolute flex flex-col justify-start mwTiny:justify-center items-center">
            <h1 className="mw-bold text-[2rem] mw4k:text-[3rem] mwSmallDesktop:text-[1.5rem] mwTiny:text-[1rem] text-center mt-[3rem] mwTiny:mt-0 mwTiny:mb-[2rem]">
              Congrats on minting {numberMinted} Multiversal Walker
              {Number(numberMinted) >= 2 && "s"}!
            </h1>
            <a
              href={`https://etherscan.io/tx/${hash}`}
              target="_blank"
              rel="noreferrer"
              className="flex text-[2rem] mwSmallDesktop:text-[1.5rem] mwTiny:text-[1rem] text-mw-black mw-bold very-clicky justify-center items-center absolute mwTiny:relative bottom-5 mwSmallDesktop:bottom-14 mw4k:bottom-[7rem] underline underline-offset-8 border-2 border-solid border-black p-4 bg-mw-offwhite"
            >
              Transaction{" "}
              <img
                src={etherscan}
                alt="etherscan logo"
                className="w-[5rem] mwSmallDesktop:w-[3.75rem] mwTiny:w-[2.5rem] ml-4"
              />
            </a>
          </div>
        </motion.div>
      )}
      {(minting || minted) && (
        <motion.div
          key="blurryBGFunky"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}
          className="overflow-hidden absolute bg-mw-offwhite w-screen h-screen bg-opacity-70 backdrop-blur-lg z-[-1]"
        >
          <img
            src={avatars[currentAvatar]}
            key="funkyGuy"
            alt="your portal guide"
            className="-scale-x-100 w-[43.75rem] mw4k:w-[100rem] mwSmallDesktop:w-[30rem] h-[43.75rem] mw4k:h-[100rem] mwSmallDesktop:h-[30rem] mwTiny:w-[20rem] mwTiny:h-[20rem] absolute bottom-0 right-0 mwMobile:-right-[20rem] mwTiny:-right-[30%] z-[40] select-none"
          />
          <MintingSpinner minting={minting} minted={minted} />
          {minted && (
            <button
              className="absolute bottom-20 mw4k:bottom-[15rem] mwSmallDesktop:bottom-28 mwTiny:bottom-20 mwTiny:left-[20%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 mw-light border-2 border-solid rounded-3xl border-black z-30 cursor-pointer"
              onClick={() => dispatch({ type: types.RESET_STATE })}
            >
              <MdClose className="text-[5rem] mw4k:text-[10rem] mwSmallDesktop:text-[3rem] cursor-pointer" />
            </button>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
