import React, { createContext, useState, useContext } from "react";
import { useLocation } from "react-router-dom";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [accounts, setAccounts] = useState();
  const [mobileNav, setMobileNav] = useState(false);
  const [playStatus, setPlayStatus] = useState(false);
  const [anomalyPlayStatus, setAnomalyPlayStatus] = useState(false);
  const [currentSong, setCurrentSong] = useState(0);
  const [anomalyCurrentSong, setAnomalyCurrentSong] = useState(0);
  const [currentAvatar, setCurrentAvatar] = useState(0);
  const songs = [
    {
      name: "Fever the Ghost",
      artist: "Source",
      url: "https://mw_content.storage.googleapis.com/Music/Fever%20The%20Ghost%20-%20SOURCE%20(official%20music%20video).mp3",
    },
    {
      name: "Around the World",
      artist: "Daft Punk",
      url: "https://mw_content.storage.googleapis.com/Music/Daft%20Punk%20-%20Around%20the%20World%20(Official%20Audio).mp3",
    },
    {
      name: "The Walker",
      artist: "Fitz And The Tantrums",
      url: "https://mw_content.storage.googleapis.com/Music/Fitz%20%26%20The%20Tantrums%20-%20The%20Walker%20(Audio).mp3",
    },
    {
      name: "How You Like Me Now?",
      artist: "The Heavy",
      url: "https://mw_content.storage.googleapis.com/Music/How%20You%20Like%20Me%20Now.mp3",
    },
  ];
  const anomalySongs = [
    {
      name: "Feel Good Inc.",
      artist: "Gorillaz",
      url: "https://storage.googleapis.com/mw_content/Music/Gorillaz%20-%20Feel%20Good%20Inc%20HD.mp3",
    },
    {
      name: "Crazy Train",
      artist: "Ozzy Osbourne",
      url: "https://storage.googleapis.com/mw_content/Music/Ozzy%20Osbourne%20-%20Crazy%20Train%20%5BHigh%20Quality%5D.mp3",
    },
  ];

  const [audio] = useState(new Audio());
  const [anomalyAudio] = useState(new Audio());

  const play = () => {
    if (pathName === "/anomaly") {
      setAnomalyPlayStatus(true);
      setPlayStatus(false);
      audio.pause();
      audio.currentTime = 0;
      if (anomalyCurrentSong === 0 && anomalyAudio.src === "") {
        anomalyAudio.src = anomalySongs[0].url;
      }
      anomalyAudio.play();
    } else {
      setPlayStatus(true);
      setAnomalyPlayStatus(false);
      anomalyAudio.pause();
      anomalyAudio.currentTime = 0;
      if (currentSong === 0 && audio.src === "") {
        audio.src = songs[0].url;
      }
      audio.play();
    }
  };

  const pause = () => {
    anomalyAudio.pause();
    audio.pause();
    setPlayStatus(false);
    setAnomalyPlayStatus(false);
  };

  const next = (curr) => curr + 1;
  const prev = (curr) => curr - 1;

  const iterateSongs = (op) => {
    if (pathName === "/anomaly") {
      audio.pause();
      setPlayStatus(false);
      setAnomalyPlayStatus(false);
      anomalyAudio.pause();
      const curr =
        ((op === "next" ? next(anomalyCurrentSong) : prev(anomalyCurrentSong)) +
          anomalySongs.length) %
        anomalySongs.length;
      anomalyAudio.src = anomalySongs[curr].url;
      anomalyAudio.load();
      anomalyAudio.play();
      setAnomalyPlayStatus(true);
      setAnomalyCurrentSong(curr);
    } else {
      anomalyAudio.pause();
      setAnomalyPlayStatus(false);
      setPlayStatus(false);
      audio.pause();
      const curr =
        ((op === "next" ? next(currentSong) : prev(currentSong)) +
          songs.length) %
        songs.length;
      audio.src = songs[curr].url;
      audio.load();
      audio.play();
      setPlayStatus(true);
      setCurrentSong(curr);
    }
  };

  const stop = () => {
    pause();
    if (pathName === "/anomaly") {
      anomalyAudio.currentTime = 0;
    } else {
      audio.currentTime = 0;
    }
  };

  const avatars = [
    "https://mw_content.storage.googleapis.com/WebsiteAssets/01.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/02.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/03.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/04.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/05.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/06.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/07.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/08.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/09.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/10.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/11.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/12.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/13.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/14.png",
    "https://mw_content.storage.googleapis.com/WebsiteAssets/15.png",
  ];

  const nextAvatar = () => {
    const curr = (currentAvatar + 1 + avatars.length) % avatars.length;
    setCurrentAvatar(curr);
  };

  const previousAvatar = () => {
    const curr = (currentAvatar - 1 + avatars.length) % avatars.length;
    setCurrentAvatar(curr);
  };

  const randomizeAvatar = () => {
    const curr = Math.floor(Math.random() * avatars.length);
    setCurrentAvatar(curr);
  };

  const pathName = useLocation().pathname;
  return (
    <AppContext.Provider
      value={{
        accounts,
        setAccounts,
        mobileNav,
        setMobileNav,
        playStatus,
        setPlayStatus,
        anomalyPlayStatus,
        songs,
        anomalySongs,
        currentSong,
        anomalyCurrentSong,
        play,
        pause,
        iterateSongs,
        stop,
        pathName,
        avatars,
        currentAvatar,
        nextAvatar,
        previousAvatar,
        randomizeAvatar,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within the AppProvider");
  }
  return context;
};
