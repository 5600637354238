import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import types from "../../data/types.json";
import { useAppContext } from "../../context/AppContext";
import useMedia from "../../hooks/useMediaQuery";

export default function Avatar({ avatarState, dispatch }) {
  const { avatars, currentAvatar } = useAppContext();
  const width = useMedia(
    [
      "(min-width: 2160px)",
      "(min-width: 1882px)",
      "(min-width: 1280px)",
      "(min-width: 769px)",
      "(min-width: 200px)",
    ],
    ["80rem", "37.5rem", "20rem", "35rem", "20rem"]
  );
  const height = useMedia(
    [
      "(min-width: 2160px)",
      "(min-width: 1882px)",
      "(min-width: 1280px)",
      "(min-width: 769px)",
      "(min-width: 200px)",
    ],
    ["80rem", "37.5rem", "20rem", "35rem", "20rem"]
  );
  const left = useMedia(
    [
      "(min-width: 2160px)",
      "(min-width: 1882px)",
      "(min-width: 1280px)",
      "(min-width: 769px)",
      "(min-width: 200px)",
    ],
    ["32%", "32%", "25%", "15%", "50%"]
  );
  const variants = {
    selecting: { opacity: 1, width: width, height: height },
    selected: {
      opacity: 1,
      width: "15.625rem",
      height: "15.625rem",
      top: "29%",
      left: left,
    },
  };
  return (
    <AnimatePresence>
      {!avatarState && (
        <div className="text-[4rem] h-fit mw4k:text-[8rem] mwSmallDesktop:text-[3rem] mwTiny:text-[2rem] text-white mw-bold absolute bottom-[8%] mwMobile:bottom-[10%] mw4k:bottom-[5%] mwSmallDesktop:bottom-[14%] mwTiny:top-[20%] mwTiny:p-2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[81] bg-black bg-opacity-40 tracking-wide w-screen text-center">
          Select your portal guide
        </div>
      )}
      <motion.img
        key="avatar"
        initial={{ opacity: 0 }}
        animate={!avatarState ? "selecting" : "selected"}
        variants={variants}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 1, type: "spring" }}
        src={avatars[currentAvatar]}
        alt="Randomized Tim"
        onClick={() =>
          !avatarState && dispatch({ type: types.SELECTED_AVATAR })
        }
        className={`border-2 border-white/60 border-solid avatarShadow
      bg-mw-purple absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 ${
        !avatarState
          ? "cursor-pointer rounded-3xl"
          : "cursor-default rounded-full"
      }`}
      />
    </AnimatePresence>
  );
}
