import React from "react";
import manWalk from "../../assets/Multipaper/MW_ManWalk-Sign.svg";

const Roadmap = ({roadmapText}) => {
  return (
    <section className="mx-auto w-screen text-mw-purple -mt-[16.2rem] mwSmallDesktop:-mt-[7rem] mwMobile:-mt-2 mwTiny:mt-1 overflow-hidden mwMobile:mb-24 mwSmallDesktop:mb-0">
      <div className="flex flex-col items-center justify-center z-10">
        <div className="flex w-screen items-center mwSmallDesktop:w-fit mwSmallDesktop:justify-center mwMobile:justify-center mwTiny:ml-0 mwSmallDesktop:-ml-[5%] ml-[42rem] mwMobile:-ml-4 mwMobile:mb-8 mwSmallDesktop:pt-4">
          <img
            src={manWalk}
            alt="crossroad guy"
            className="mwTiny:w-[55px] mwMobile:w-[15%] mwSmallDesktop:w-[25%] w-[10.5%] -rotate-[10deg] mwTiny:ml-6 mwMobile:ml-14 mwMobile:mt-4 ml-1 mwTiny:mr-5 mwTiny:-mt-2 -mt-4 z-10"
          />
          <h2 className="mw-bold z-10 mwTiny:text-[1.85rem] mwSmallDesktop:text-[5.8rem] mwMobile:text-[4rem] text-[5.8rem] mwTiny:mt-2 mt-[3.6rem] mwTiny:ml-0 ml-[8.2rem] mwSmallDesktop:ml-[3rem] mwMobile:ml-10 mwTiny:tracking-wide tracking-tight w-screen mwSmallDesktop:w-full mwMobile:w-fit">
            ROADMAP
          </h2>
        </div>
        <p className="text-mw-black mwTiny:text-[9px] mwMobile:text-[1.23rem] text-[2rem] mwTiny:tracking-normal mwTiny:leading-[11.5px] mwMobile:leading-[1.5rem] leading-[2.35rem] mwTiny:w-[83%] mwMobile:w-[90%] w-[64%] text-center mwTiny:mt-[0.2rem] mwMobile:mt-0 mt-[2.4rem]">
          {roadmapText}
        </p>
      </div>
    </section>
  );
};

export default Roadmap;
