import React, { useReducer, useEffect, useCallback } from "react";
import { ethers, BigNumber } from "ethers";
import { AnimatePresence, motion } from "framer-motion";
import types from "../data/types.json";
import abi from "../data/abi.json";
import signatures from "../data/multilist_signatures.json";
import HeroVideo from "../components/HeroVideo";
import MintTerminal from "../components/mint/MintTerminal";
import MintButton from "../components/mint/MintButton";
import MintError from "../components/mint/MintError";
import MintConfirmation from "../components/mint/MintConfirmation";
import SelectAvatar from "../components/mint/SelectAvatar";
import Avatar from "../components/mint/Avatar";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import MetaMask from "../assets/Icons/metamask.svg";
import { useAppContext } from "../context/AppContext";
import { TestButtons } from "../components/mint/TestButtons";

const mainnet = "0xF15C37b6fB44EDDcB2F4Fd6CaA0Ec85e45cF4D48";
// const testnet = "0x1cdE2635C9d9C32a0CEa5D815de7132e222D7eC0";
const testButton = false;

const initialState = {
  showUI: false,
  avatarState: false,
  account: "",
  contract_addr: mainnet,
  signature: "",
  mintAmount: 0,
  maxMintAmount: 0,
  numberMinted: 0,
  aux: 0,
  minting: false,
  minted: false,
  terminalOpen: false,
  hash: "",
  canMint: false,
  saleState: 0,
  publicPrice: 0,
  multiPrice: 0,
  clicked: false,
  supply: 0,
  error: "",
};

function reducer(state, action) {
  switch (action.type) {
    case types.RESET_STATE:
      return initialState;
    case types.SET_ACCOUNT:
      return {
        ...state,
        account: action.payload.account,
        avatarState: false,
      };
    case types.SELECTED_AVATAR:
      return { ...state, avatarState: true };
    case types.SET_SIGNATURE:
      return { ...state, signature: action.payload };
    case types.CAN_THEY_MINT_THO:
      return { ...state, canMint: action.payload };
    case types.SET_SALE_STATE:
      return { ...state, saleState: action.payload };
    case types.SET_AUX: {
      return { ...state, aux: action.payload };
    }
    case "SET_TERMINAL_OPEN": {
      return { ...state, terminalOpen: action.payload };
    }
    case types.SET_MINT_PRICE:
      return {
        ...state,
        publicPrice: action.payload.publicPrice,
        multiPrice: action.payload.multiPrice,
        supply: action.payload.supply,
      };
    case types.SET_MAX_MINT_AMOUNT:
      return { ...state, maxMintAmount: action.payload.maxMintAmount };
    case types.GET_AMOUNT_ALREADY_MINTED:
      return {
        ...state,
        mintAmount: Math.max(0, action.payload.mintAmount),
        numberMinted: action.payload.numberMinted,
        maxMintAmount: action.payload.maxMintAmount,
        canMint: action.payload.canMint,
      };
    case types.INCREMENT_MINT:
      return {
        ...state,
        mintAmount: Math.min(state.maxMintAmount, state.mintAmount + 1),
      };
    case types.DECREMENT_MINT:
      return { ...state, mintAmount: Math.max(1, state.mintAmount - 1) };
    case types.MINTING:
      return { ...state, minting: true };
    case types.MINTED:
      return {
        ...state,
        minting: false,
        minted: true,
        canMint: action.payload.canMint,
        numberMinted: action.payload.numberMinted,
        hash: action.payload.hash,
        mintAmount: action.payload.mintAmount,
      };
    case types.SHOW_UI:
      return { ...state, showUI: true };
    case types.CLICKED:
      return { ...state, clicked: true };
    case types.ERROR:
      return { ...state, error: action.payload, showUI: true };
    case "SPOOF_MINTING":
      return { ...state, minting: true, minted: false };
    case "TEST_MINT_ANIMATION":
      return { ...state, minting: false, minted: true };
    case "RESET_MINT_CYCLE":
      return { ...state, minting: false, minted: false };
    case "CAN_MINT_TWO":
      return { ...state, canMint: true, maxMintAmount: 2, mintAmount: 1 };
    case "CAN_MINT_THREE":
      return { ...state, canMint: true, maxMintAmount: 3, mintAmount: 1 };
    case "CHANGE_AVATAR_STATE":
      return { ...state, avatarState: !state.avatarState, canMint: true };
    case "SPOOF_NOT_ON_LIST":
      return { ...state, signature: null };
    default:
      throw new Error();
  }
}

export default function Mint() {
  const { avatars, currentAvatar, mobileNav } = useAppContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    showUI,
    avatarState,
    account,
    canMint,
    contract_addr,
    signature,
    minting,
    minted,
    saleState,
    clicked,
    supply,
    terminalOpen,
    error,
  } = state;

  // ! Formats ether price from hex
  const formatEther = (hex) => {
    return ethers.utils.formatEther(BigNumber.from(hex));
  };

  // ! If an account is detected and multilist sale is active, it will try to find a valid signature
  const getSigs = useCallback(
    (acct) => {
      if (acct && saleState === 2) {
        dispatch({
          type: types.SET_SIGNATURE,
          payload: signatures.MULTI[acct],
        });
      }
    },
    [saleState]
  );

  // ! Gets amount the user is able to mint, if they are able to mint
  const getMintAmount = useCallback(
    (contractNumberMinted, aux) => {
      let maxMintAmount = 0;
      let minMintsLeft = 0;
      const formattedNumberMinted =
        BigNumber.from(contractNumberMinted).toNumber();
      const formattedAux = BigNumber.from(aux).toNumber();

      // ! Public Max/Min
      if (saleState === 1) {
        if (formattedAux === 1) {
          minMintsLeft = formattedNumberMinted === 1 ? 1 : 0;
          maxMintAmount = 1;
        } else {
          minMintsLeft = formattedNumberMinted < 2 ? 1 : 0;
          maxMintAmount =
            formattedNumberMinted === 0 ? 2 : 2 - formattedNumberMinted;
        }
      }

      // ! Multilist Max/Min
      if (signature && saleState === 2) {
        if (formattedAux !== 0) {
          minMintsLeft = 0;
          maxMintAmount = 0;
        } else if (signature[3]) {
          minMintsLeft = 1;
          maxMintAmount = 3;
        } else if (signature[2]) {
          minMintsLeft = 1;
          maxMintAmount = 2;
        } else if (signature[1]) {
          minMintsLeft = 1;
          maxMintAmount = 1;
        }
      }

      return {
        maxMintAmount,
        minMintsLeft,
        formattedNumberMinted,
      };
    },
    [saleState, signature]
  );

  // ! Connects your wallet and gets properly formatted address
  const connectWallet = async () => {
    if (!window.ethereum) return alert("Please install Metamask");
    if (!clicked) {
      try {
        dispatch({ type: types.CLICKED });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const walletAddress = await signer.getAddress();
        getSigs(walletAddress);
        dispatch({
          type: types.SET_ACCOUNT,
          payload: { account: walletAddress },
        });
      } catch (err) {
        dispatch({ type: types.ERROR, payload: err });
      }
    }
  };

  // ! Sets up the mint cycle state after connection and reconnects if possible
  useEffect(() => {
    document.querySelector("html").style.overflow = "hidden";
    if (window.ethereum) {
      const checkWalletConnected = async () => {
        if (!window.ethereum) return alert("Please install Metamask");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await provider.send("eth_accounts", []);
        if (accounts.length !== 0) {
          const signer = provider.getSigner();
          const contract = new ethers.Contract(contract_addr, abi, signer);
          const state = await contract.saleState();
          const walletAddress = await signer.getAddress();
          const getAux = await contract.getAux(walletAddress);
          const publicPrice = await contract.publicPrice();
          const multiPrice = await contract.multiPrice();
          const supply = await contract.totalSupply();
          const contractMints = await contract.numberMinted(walletAddress);
          const mints = getMintAmount(contractMints, getAux);
          try {
            dispatch({
              type: types.SET_ACCOUNT,
              payload: { account: walletAddress },
            });
            dispatch({
              type: types.SET_SALE_STATE,
              payload: state,
            });
            dispatch({
              type: types.SET_AUX,
              payload: BigNumber.from(getAux._hex).toNumber(),
            });
            getSigs(walletAddress);
            dispatch({
              type: types.SET_MINT_PRICE,
              payload: {
                publicPrice: Number(formatEther(publicPrice)),
                multiPrice: Number(formatEther(multiPrice)),
                supply: BigNumber.from(supply._hex).toNumber(),
              },
            });
            dispatch({
              type: types.GET_AMOUNT_ALREADY_MINTED,
              payload: {
                mintAmount: mints.minMintsLeft,
                numberMinted: mints.formattedNumberMinted,
                maxMintAmount: mints.maxMintAmount,
                canMint: mints.minMintsLeft === 0 ? false : true,
              },
            });
            dispatch({ type: types.SHOW_UI });
          } catch {
            dispatch({
              type: types.ERROR,
              payload: "No authorized account found",
            });
          }
        }
      };
      checkWalletConnected();
      return () => {
        document.querySelector("html").style.overflow = "auto";
      };
    }
  }, [contract_addr, getMintAmount, getSigs, account]);

  // ! If the user switches accounts or saleState is changed -
  // ! it a re-render is fired
  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const reset = () => dispatch({ type: types.RESET_STATE });
      window.ethereum.on("accountsChanged", reset);
      const listenForSalesEvent = async () => {
        const accounts = await provider.send("eth_accounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contract_addr, abi, signer);
        if (accounts !== 0) {
          contract.on("SetSaleState", (error, event) => {
            dispatch({
              type: types.SET_SALE_STATE,
              payload: BigNumber.from(event._hex).toNumber(),
            });
          });
        }
      };
      listenForSalesEvent();
      return () => {
        window.ethereum.removeListener("accountsChanged", reset);
      };
    }
  }, [contract_addr]);

  // ! Resets state if the user cancels a transaction
  useEffect(() => {
    if (
      [
        "User rejected the request.",
        "MetaMask Tx Signature: User denied transaction signature.",
        "User rejected the transaction",
        "User denied account authorization.",
      ].includes(error.message)
    ) {
      dispatch({ type: types.RESET_STATE });
    }
  });

  // ! Mobile Nav re-locks overflow style
  useEffect(() => {
    if (!mobileNav) {
      document.querySelector("html").style.overflow = "hidden";
    }
    return () => (document.querySelector("html").style.overflow = "auto");
  }, [mobileNav]);

  useEffect(() => {
    if (terminalOpen) {
      document.querySelector("html").style.overflow = "auto";
    } else {
      document.querySelector("html").style.overflow = "hidden";
    }
  });

  return (
    <section className="">
      <AnimatePresence>
        {showUI && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1 }}
            className="h-screen w-screen absolute z-10 horizontal:hidden"
          >
            {canMint && !avatarState && !minting && !minted && !error && (
              <SelectAvatar dispatch={dispatch} />
            )}
            {canMint && !minting && !minted && !error && (
              <Avatar avatarState={avatarState} dispatch={dispatch} />
            )}
            {!error && <MintConfirmation state={state} dispatch={dispatch} />}
            {canMint && !minting && !minted && avatarState && !error && (
              <div className="overflow-hidden mwTiny:overflow-auto flex flex-col absolute z-20 justify-center items-center w-screen h-screen text-mw-black text-6xl mt-[4rem] mwSmallDesktop:mt-[1rem]">
                <motion.div
                  key="mintCounter"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ delay: 0.15 }}
                  className="dropShadow bg-mw-offwhite bg-opacity-40 backdrop-blur-lg rounded-3xl mw4k:w-[90rem] mwTiny:h-fit mwTiny:w-screen"
                >
                  <MintTerminal state={state} dispatch={dispatch} />
                  <MintButton state={state} dispatch={dispatch} abi={abi} />
                </motion.div>
              </div>
            )}
            {(error || !canMint) && <MintError error={error} state={state} />}
          </motion.div>
        )}
      </AnimatePresence>
      {testButton && <TestButtons dispatch={dispatch} state={state} />}
      <AnimatePresence>
        {!account && window.ethereum && !mobileNav && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1 }}
            key="connectionToMetaMask"
            className="w-screen z-30"
          >
            <div className="hidden mw:flex mw4k:flex mwSmallDesktop:flex h-[20.625rem] absolute bottom-[12.2%] items-center z-30 w-screen">
              <button
                className="w-[21.875rem] h-[20.625rem] rounded-2xl ml-[14.5rem] opacity-50 cursor-pointer z-30"
                onClick={connectWallet}
              />
              <div className="flex flex-col">
                <h1 className="text-mw-offwhite text-[4rem] flex mw-bold animate-bounce justify-center items-center ml-8 z-40 select-none strokedText drop-shadow-2xl">
                  <FaArrowAltCircleLeft className="mr-4 mb-4" /> Click the Mint
                  Button!
                </h1>
              </div>
            </div>
            <button
              className="hidden mwTiny:flex mwMobile:flex absolute z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-mw-offwhite text-[2rem] bg-mw-offwhite bg-opacity-25 border-[20px] border-solid border-white rounded-[24px] p-4 mw-bold gradient-border"
              onClick={connectWallet}
            >
              Connect to the Multiverse
            </button>
          </motion.div>
        )}
      </AnimatePresence>
      <HeroVideo />
      {!window.ethereum && (
        <>
          <a
            href="https://metamask.io/"
            target="_blank"
            rel="noreferrer"
            className="mwTiny:hidden mwMobile:hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mw-bold text-[2rem] z-20 text-mw-offwhite bg-mw-black rounded-[24px] w-fit p-4 bg-opacity-40 select-none text-center flex items-center justify-center gradient-border border-[20px] border-solid border-white"
          >
            Install Metamask{" "}
            <img src={MetaMask} alt="Meta Mask Fox" className="w-[20%]" />
          </a>
          <h1 className="hidden w-[90%] mwTiny:flex mwMobile:flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mw-bold text-[2rem] z-20 text-mw-offwhite bg-mw-black rounded-[24px] p-4 bg-opacity-40 select-none text-center items-center justify-center gradient-border border-[20px] border-solid border-white">
            Please use the MetaMask Browser
          </h1>
        </>
      )}
      {account && !error && (
        <div className="overflow-hidden hidden horizontal:flex items-center justify-center absolute h-full w-full z-10 top-0 left-0">
          <img
            src={avatars[currentAvatar]}
            className="scale-[55%] absolute z-10 right-0"
            alt="your portal guide"
          />
          <h1 className="text-4xl w-[90%] h-fit text-white underline mw-bold underline-offset-8 p-3 bg-mw-black/50 rounded-2xl border-solid border-[0.5px] border-white text-center z-20 mt-[32%]">
            Device Height is too short for multiversal travel
          </h1>
        </div>
      )}
      {supply && account && !minting && !error && (
        <h1 className="horizontal:hidden absolute text-mw-offwhite-purple bottom-[5%] mw4k:bottom-0 mwTiny:bottom-[30%] mw-bold left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-2xl">
          Walkers Minted: {supply}/5555
        </h1>
      )}
    </section>
  );
}
