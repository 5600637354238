import React from "react";
import types from "../../data/types.json";
import { FaEthereum } from "react-icons/fa";
import { useAppContext } from '../../context/AppContext';

export default function MintCounter({ state, dispatch }) {
  const {mobileNav} = useAppContext();
  const {
    canMint,
    mintAmount,
    maxMintAmount,
    saleState,
    publicPrice,
    multiPrice,
  } = state;
  React.useEffect(() => {
    dispatch({ type: "SET_TERMINAL_OPEN", payload: true });
  }, [dispatch, mobileNav]);
  return (
    <div className="overflow-hidden mwTiny:overflow-auto flex flex-col justify-start items-center h-[25rem] mw4k:h-[50rem] mwSmallDesktop:h-[15rem] mwTiny:h-[17rem] p-10 mwSmallDesktop:p-4 mwSmallDesktop:pt-0 mwTiny:p-2 space-y-2 mwSmallDesktop:space-y-1 mwTiny:space-y-0 mt-[9rem] mwTiny:mt-[18rem] z-50">
      <div className="absolute -top-[1.2%] right-0 bg-mw-purple flex justify-center items-center h-[9.375rem] mwSmallDesktop:h-[7rem] w-full rounded-tr-3xl border border-mw-offwhite/60 border-solid">
        <p className="text-[3.5rem] mw4k:text-[4.5rem] mwSmallDesktop:text-[2.5rem] mw-bold text-mw-offwhite-purple ml-[24%] mwTiny:ml-0 mw4k:ml-0 mwTiny:hidden">
          Pre-Portal Check
        </p>
      </div>
      <div className="flex justify-center items-center mw4k:text-[8rem] mwTiny:text-[4rem] mwTiny:absolute mwTiny:top-[35%]">
        <button
          disabled={!canMint || mintAmount === 0}
          onClick={() => dispatch({ type: types.DECREMENT_MINT })}
          className="rounded-xl border-mw-black border-2 h-24 w-24 mw4k:h-[10rem] mw4k:w-[10rem] mwTiny:h-[5rem] mwTiny:w-[5rem] mr-2 bg-mw-offwhite-purple"
        >
          -
        </button>
        <input
          type="number"
          readOnly
          value={mintAmount}
          className="w-[10rem] mw4k:h-[10rem] mw4k:w-[10rem] mwTiny:h-[5rem] mwTiny:w-[8rem] text-center bg-transparent"
        />
        <button
          disabled={!canMint || mintAmount >= maxMintAmount}
          onClick={() => dispatch({ type: types.INCREMENT_MINT })}
          className={`border-mw-black border-2 h-24 w-24 mw4k:h-[10rem] mw4k:w-[10rem] mwTiny:h-[5rem] mwTiny:w-[5rem] ml-2 bg-mw-offwhite-purple ${
            maxMintAmount > 1 && mintAmount < maxMintAmount && canMint
              ? "gradient-border border-0"
              : "rounded-xl"
          }`}
        >
          +
        </button>
      </div>
      {canMint && (
        <div className="flex justify-center items-center h-[8rem] mwTiny:h-[8rem] underline underline-offset-8 mw4k:text-[7rem] mwTiny:text-[1.9rem]">
          <p>
            mint price:
            {Number((saleState === 1 ? publicPrice : multiPrice) * mintAmount).toPrecision(3)}
          </p>
          <FaEthereum className="ml-3 text-mw-purple mb-3" />
        </div>
      )}
      <div className="text-center text-4xl z-10 mw4k:text-[3.8rem] mwTiny:text-[1.5rem] mw4k:pt-10 mw4k:pb-2">
        <p>
          You can mint {maxMintAmount} Multiversal Walker
          {maxMintAmount === 2 && "s"}!{" "}
        </p>
      </div>
      {maxMintAmount > 1 && saleState === 2 && mintAmount !== maxMintAmount && (
        <div className="flex h-[50px] w-[50px] mwTiny:h-[30px] mwTiny:w-[30px] absolute -top-[5%] mwTiny:-top-[2%] -right-[1%] mwTiny:right-[0%] group">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-[50px] w-[50px] mwTiny:h-[30px] mwTiny:w-[30px] bg-red-700"></span>
          <p className="absolute hidden group-hover:flex top-[100%] w-[250px] rounded-[10px] bg-mw-offwhite border-[0.25px] border-mw-black border-solid opacity-75 p-1 mw-normal text-[1rem] text-center mwMobile:-right-[4rem] mwTiny:opacity-0">
            Multiple Multilist signatures detected, {maxMintAmount} mints are
            available to you. Minting 1 NFT will consume the signature, you will
            not be able to mint again!
          </p>
        </div>
      )}
      <div className="hidden mw4k:flex h-full w-full my-8">
        <img
          src="https://storage.googleapis.com/mw_content/Videos/Banner3%20(1).gif"
          alt="tims tims tims tims tims tims 4k"
        />
      </div>
    </div>
  );
}
