import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import cloud from "../assets/Icons/MW_Cloud.svg";
import { useAppContext } from "../context/AppContext";

const AnimatedClouds = () => {
  const { pathName } = useAppContext();
  const extraClouds = pathName !== "/";

  return (
    <div
      className={`background-wrap short:mt-1 z-[10] ${
        pathName !== "/" && pathName !== "/multipaper" && "bg-mw-light-purple"
      }`}
    >
      <AnimatePresence>
        <div className="hidden mwMobile:flex mw:flex mw4k:flex" key="animation">
          <motion.img
            className="w-[200px] h-[200px] shorter:hidden absolute top-0 z-0"
            src={cloud}
            alt="cloud"
            initial={{ right: "-200px" }}
            animate={{ left: "-200px" }}
            exit={{ opacity: 0 }}
            transition={{ ease: "linear", duration: 35, repeat: Infinity }}
          />
          <motion.img
            className="w-[200px] h-[200px] shorter:hidden absolute top-[5%] z-0"
            src={cloud}
            alt="cloud"
            initial={{ right: "-350px" }}
            animate={{ left: "-200px" }}
            exit={{ opacity: 0 }}
            transition={{ ease: "linear", duration: 30, repeat: Infinity }}
          />
          {extraClouds && (
            <>
              <motion.img
                className="w-[200px] h-[200px] shorter:w-[100px] shorter:h-[100px] absolute top-[10%] z-0"
                src={cloud}
                alt="cloud"
                initial={{ right: "-600px" }}
                animate={{ left: "-200px" }}
                exit={{ opacity: 0 }}
                transition={{ ease: "linear", duration: 30, repeat: Infinity }}
              />
              <motion.img
                className="w-[200px] h-[200px] shorter:w-[100px] shorter:h-[100px] absolute top-[15%] z-0"
                src={cloud}
                alt="cloud"
                initial={{ right: "-300px" }}
                animate={{ left: "-200px" }}
                exit={{ opacity: 0 }}
                transition={{ ease: "linear", duration: 25, repeat: Infinity }}
              />
            </>
          )}
        </div>
        <div className="hidden mwTiny:flex" key="no animation">
          <img
            style={{
              width: 100,
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 0,
            }}
            src={cloud}
            alt="cloud"
          />
          <img
            style={{
              width: 100,
              position: "absolute",
              top: 50,
              left: 15,
              zIndex: 0,
            }}
            src={cloud}
            alt="cloud"
          />
        </div>
      </AnimatePresence>
    </div>
  );
};

export default AnimatedClouds;
