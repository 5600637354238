import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import data from "../data/notification.json";
import playImage from "../assets/Icons/MW_Music Button.svg";
import stopImage from "../assets/Icons/MW_Music Button-PAUSE.svg";
import { useAppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import { FaStepForward, FaStepBackward, FaStop, FaPlay } from "react-icons/fa";

const HomepageFooter = () => {
  const {
    playStatus,
    anomalyPlayStatus,
    pathName,
    songs,
    anomalySongs,
    play,
    pause,
    iterateSongs,
    stop,
    currentSong,
    anomalyCurrentSong,
  } = useAppContext();
  const multipaper = pathName === "/multipaper";
  const anomaly = pathName === "/anomaly";
  const news = pathName === "/";
  const whichPlayStatus = anomaly ? anomalyPlayStatus : playStatus;
  const [swapped, setSwapped] = useState(false);

  const handlePlayer = () => {
    swapped && play();
    if (pathName === "/anomaly") {
      if (!anomalyPlayStatus) {
        setSwapped(false);
        play();
      } else {
        pause();
      }
    } else {
      if (!playStatus) {
        setSwapped(false);
        play();
      } else {
        pause();
      }
    }
  };

  React.useEffect(() => {
    setSwapped(false);
    news && anomalyPlayStatus && setSwapped(true);
    anomaly && playStatus && setSwapped(true);
  }, [anomaly, anomalyPlayStatus, news, playStatus, swapped]);

  return (
    <AnimatePresence>
      <footer
        className={`overflow-hidden z-[10] select-none short:h-20 shorter:top-[80%] shorter:h-28 short:bottom-0 mwSmallDesktop:top-[78%] mwTiny:h-30 w-full mwTiny:bottom-1 mwMobile:bottom-12 mwTiny:flex short:z-10 mw-normal mw4k:mb-[7rem] ${
          multipaper
            ? "mwTiny:hidden mwMobile:hidden mwSmallDesktop:mb-4 h-[7.5rem]"
            : news 
            ? "fixed h-[8rem] mwTiny:flex mwTiny:flex-col mwTiny:bottom-[10%] bottom-14"
            : "fixed h-[8rem] mwTiny:flex mwTiny:flex-col mwTiny:bottom-[10%] bottom-14 w-fit"
        }`}
      >
        <div className="flex relative justify-between items-center mt-[0.25rem] mx-[2.8rem] mb-0 mwMobile:mx-[1rem] mwTiny:mx-[1rem]">
          <div className="relative w-fit h-fit p-4 overflow-hidden flex-shrink-0 flex flex-col ml-6 mwMobile:-ml-6 mwTiny:ml-6 mwTiny:mr-5 mwTiny:h-[50px] mwTiny:min-w-[80px] mwTiny:hidden mwMobile:hidden">
            <div
              className={`w-[285px] border-solid border-[0.25px] border-mw-black rounded-lg flex items-center h-24 content-center mwTiny:h-[50px] mwTiny:min-w-[80px] ${
                swapped ? "gradient-background animate-pulse" : "bg-mw-offwhite"
              }`}
            >
              <img
                src={
                  swapped
                    ? playImage
                    : !whichPlayStatus && !swapped
                    ? playImage
                    : stopImage
                }
                alt="radio play and pause"
                className="h-[5.5rem] w-[180px] mwTiny:h-[45px] mwTiny:w-[70px]"
                onClick={handlePlayer}
              />
              <button
                onClick={() => iterateSongs("prev")}
                className="mr-3 mt-[3px] text-mw-purple"
              >
                <FaStepBackward className="text-[1.4rem] stroke-mw-black stroke-[12px]" />
              </button>
              <button onClick={stop} className="mr-3 mt-[3px] text-mw-purple">
                <FaStop className="text-[1.4rem] stroke-mw-black stroke-[12px]" />
              </button>
              <button
                onClick={() => iterateSongs("next")}
                className="mt-[3px] text-mw-purple"
              >
                <FaStepForward className="text-[1.4rem] stroke-mw-black stroke-[12px]" />
              </button>
            </div>
            <div
              className={`absolute top-[15%] right-[10%] z-10 w-[156px] ${
                playStatus || anomalyPlayStatus ? "visible" : "hidden"
              }`}
            >
              <p className="text-mw-black mw-bold text-[0.7rem] underline text-right underline-offset-1">
                {anomalyPlayStatus
                  ? anomalySongs[anomalyCurrentSong].name
                  : songs[currentSong].name}
              </p>
              <p className="text-mw-black opacity-50 mw-normal text-[8px] w-full text-right">
                {anomalyPlayStatus
                  ? anomalySongs[anomalyCurrentSong].artist
                  : songs[currentSong].artist}
              </p>
            </div>
            <audio src={songs[currentSong].url} type="audio/mpeg"></audio>
            {swapped && (
              <h1
                className={`absolute left-[10%] top-[2%] animate-bounce flex justify-center items-center ${
                  anomaly ? "text-mw-offwhite" : "text-mw-black"
                }`}
              >
                New Playlist Available <FaPlay className="ml-2 text-[9px]" />
              </h1>
            )}
          </div>
          {news && (
            <motion.div
              transition={{ type: "spring", stiffness: 50 }}
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              className="bg-mw-offwhite min-w-mwNotification w-[450px] text-mw-black 
              rounded-lg flex items-center h-22 border-solid border-[0.25px] border-mw-black
              mwMobile:min-w-full short:h-10
              mwTiny:h-16 mwTiny:min-w-full mwTiny:rounded-md"
            >
              <div className="flex items-center justify-center h-full flex-grow px-4">
                <p className="text-[1.16rem] mwMobile:text-[1rem] mwTiny:text-[0.8rem] px-2 leading-tight text-center short:text-[0.8rem]">
                  {data.main}: {data.news}
                </p>
                {data.external ? (
                  <a
                    href={data.link}
                    target="_blank"
                    rel="noreferrer"
                    className="clicky rounded-lg mw-bold mwTiny:rounded-md bg-mw-purple text-mw-offwhite text-[1rem] mwMobile:text-[1rem] mwTiny:text-[0.8rem] p-3 short:p-1 mr-2 text-center short:text-[0.5rem] mwTiny:-mt-2 mwMobile:-mt-2"
                  >
                    More
                  </a>
                ) : (
                  <Link
                    to={data.link}
                    className="clicky rounded-lg mw-bold mwTiny:rounded-md bg-mw-purple text-mw-offwhite text-[1rem] mwMobile:text-[1rem] mwTiny:text-[0.8rem] p-3 short:p-1 mr-2 text-center short:text-[0.5rem] mwTiny:-mt-2 mwMobile:-mt-2"
                  >
                    More
                  </Link>
                )}
              </div>
            </motion.div>
          )}
        </div>
      </footer>
    </AnimatePresence>
  );
};

export default HomepageFooter;
