import React from "react";

export default function Discord({ mods }) {
  return (
    <section className="mx-auto flex flex-col justify-center items-center w-screen text-mw-purple bg-gradient-to-t from-mw-purple to-[#FFFFFF] pb-[17rem] mwTiny:pb-24 short:pb-[6rem] shorter:pb-[10rem]">
      <h2 className="mw-bold z-30 mwMobile:w-full mwSmallDesktop:w-full -mt-[4.8rem] w-[50%] text-[5.6rem] tracking-normal leading-tight text-center mwMobile:text-[3rem] mwSmallDesktop:text-[3rem] mwTiny:mt-0 mwTiny:w-screen mwTiny:text-[1.85rem]">
        DISCORD LEAD MODERATORS
      </h2>
      <div className="grid grid-cols-4 gap-x-6 gap-y-8 px-[2.6rem] mw:items-stretch mt-[10rem] items-center w-[80%] mwMobile:items-stretch mwSmallDesktop:w-full mwMobile:w-full mwMobile:mt-10 mwSmallDesktop:mt-10 mwMobile:px-[1rem] mwMobile:gap-x-2 mwMobile:gap-y-2 mwTiny:grid-cols-2 mwTiny:gap-4 mwTiny:gap-y-6 mwTiny:mt-[1.2rem] mwTiny:w-screen mwTiny:justify-items-center">
        {mods.map((mod, i) => (
          <div
            className="bg-mw-offwhite z-10 flex flex-col items-center justify-center border-solid border-[0.25px] border-mw-black p-6 rounded-[30px] w-[22rem] mwSmallDesktop:w-full h-[9rem] mwMobile:w-[100%] mwMobile:p-2 mwSmallDesktop:p-0 mwMobile:h-[7rem] mwSmallDesktop:h-[8rem] mwMobile:items-stretch mwSmallDesktop:items-stretch mwMobile:rounded-2xl mwSmallDesktop:rounded-xl mwTiny:rounded-lg mwTiny:w-[9.1rem] mwTiny:h-[3.8rem] mwTiny:-mb-[0.48rem]"
            key={mod.name}
          >
            <p className="flex flex-shrink items-center justify-center mw-bold text-[1.6rem] mt-2 text-center mwMobile:text-[1.7vw] mwSmallDesktop:text-[1.7vw] mwMobile:mt-0 mwSmallDesktop:mt-0 mwTiny:text-[0.7rem] mwTiny:mt-0">
              {mod.name}
            </p>
            <p
              className={`text-mw-black text-[1.3rem] mt-1 text-center tracking-tighter leading-[1.25rem] mwMobile:text-[1.25vw] mwSmallDesktop:text-[1.25vw] mwMobile:w-full mwSmallDesktop:w-full mwTiny:leading-[0.6rem] mwTiny:mt-0 mwTiny:text-[0.59rem] ${
                [4, 5, 9].includes(i)
                  ? "mwTiny:w-[80%] w-[40%]"
                  : [6].includes(i)
                  ? "mwTiny:w-[100%] w-[80%]"
                  : "mwTiny:w-[100%] w-[65%]"
              }`}
            >
              {mod.desc}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
