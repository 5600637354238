import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { AnimatePresence, motion } from "framer-motion";
import logo from "../assets/Icons/MW_Logo.svg";
import twitter from "../assets/Icons/MW_T_Logo.svg";
import discord from "../assets/Icons/MW_D_Logo.svg";
import instagram from "../assets/Icons/MW_IG_Logo.svg";
import medium from "../assets/Icons/MW_M_Logo.svg";
import notSoFungibles from "../assets/Icons/MW_NSF_Logo.svg";

const HamburgerMenu = () => {
  const { mobileNav, setMobileNav } = useAppContext();
  useEffect(() => {
    const html = document.querySelector("html");
    html.style.overflow = mobileNav ? "hidden" : "auto";
  }, [mobileNav]);
  const mobileLinkStyle =
    "py-[8px] w-full items-center block relative flex text-black";
  const deactivatedMobileLinkStyle =
    "py-[8px] w-full items-center block relative flex text-black opacity-25 pointer-events-none cursor-default";
  const horizontal =
    "py-[8px] w-full items-center block relative flex text-black hidden short:flex";
  return (
    <AnimatePresence className="z-50">
      {mobileNav && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="w-screen items-start h-screen mwTiny:pb-[100px] absolute top-0 px-7 pt-[108px] mwTiny:pt-[95px] mwMobile:pt-[140px] short:pt-[80px] bg-mw-offwhite bg-opacity-70 backdrop-blur-lg pointer overflow-scroll will-change short:z-20 z-50"
        >
          <div className="text-[43px] w-full font-900 flex flex-col">
            <Link
              onClick={() => setMobileNav(false)}
              to="/"
              className={horizontal}
            >
              <img
                src={logo}
                alt="tim"
                className="flex w-full h-full max-h-[100px] max-w-[350px] mb-3"
              />
            </Link>
            <Link
              onClick={() => setMobileNav(false)}
              to="/multipaper"
              className={mobileLinkStyle}
            >
              Multipaper
            </Link>
            <Link
              onClick={() => setMobileNav(false)}
              to="/portal"
              className={mobileLinkStyle}
            >
              Portal
            </Link>
            <a
              href="https://h4x.gg/pages/walkers"
              target="_blank"
              rel="noreferrer"
              className={mobileLinkStyle}
            >
              Multishop
            </a>
            <Link
              onClick={() => setMobileNav(false)}
              to="/mint"
              className={mobileLinkStyle}
            >
              Mint
            </Link>
            <Link
              onClick={() => setMobileNav(false)}
              to="/anomaly"
              className={mobileLinkStyle}
            >
              Anomaly
            </Link>
            <Link
              onClick={() => setMobileNav(false)}
              to="/gallery"
              className={deactivatedMobileLinkStyle}
            >
              Gallery
            </Link>
            <Link
              onClick={() => setMobileNav(false)}
              to="/datacenter"
              className={mobileLinkStyle}
            >
              Data Centre
            </Link>
            <div className="flex mt-7 mwTiny:mt-0 short:mt-0 items-center content-center mwMobile:pb-14 mwTiny:pb-14">
              <a
                href="https://twitter.com/MultiversalWalk/"
                target="_blank"
                rel="noreferrer"
                className="w-full flex items-center content-center"
                onClick={() => setMobileNav(false)}
              >
                <img src={twitter} alt="twitter Logo" className="h-20 w-full" />
              </a>
              <div className="relative group flex flex-col items-center justify-center w-full">
                <a
                  href="https://twitter.com/MultiversalWalk/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center content-center pointer-events-none cursor-default opacity-25"
                  onClick={() => setMobileNav(false)}
                >
                  <img
                    src={discord}
                    alt="discord Logo"
                    className="h-20 w-full"
                  />
                </a>
                <p className="absolute hidden group-hover:flex top-[100%] rounded-[10px] w-[150px] bg-mw-offwhite border-[0.25px] border-mw-black border-solid opacity-75 p-1 mw-light text-[1rem] text-center">
                  Keep an eye on our twitter!
                </p>
              </div>
              <a
                href="https://medium.com/@MultiversalWalkers"
                target="_blank"
                rel="noreferrer"
                className="w-full flex items-center content-center"
                onClick={() => setMobileNav(false)}
              >
                <img src={medium} alt="medium Logo" className="h-20 w-full" />
              </a>
              <a
                href="https://www.instagram.com/multiversal_walkers/"
                target="_blank"
                rel="noreferrer"
                className="w-full flex items-center content-center"
                onClick={() => setMobileNav(false)}
              >
                <img
                  src={instagram}
                  alt="instagram Logo"
                  className="h-20 w-full"
                />
              </a>
              <a
                href="https://twitter.com/notsofungibles"
                target="_blank"
                rel="noreferrer"
                className="w-full flex items-center content-center"
                onClick={() => setMobileNav(false)}
              >
                <img
                  src={notSoFungibles}
                  alt="notSoFungibles Logo"
                  className="h-20 w-full"
                />
              </a>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default HamburgerMenu;
