import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import logo from "../assets/Icons/MW_Logo.svg";
import twitter from "../assets/Icons/MW_T_Logo.svg";
import discord from "../assets/Icons/MW_D_Logo.svg";
import instagram from "../assets/Icons/MW_IG_Logo.svg";
import medium from "../assets/Icons/MW_M_Logo.svg";
import notSoFungibles from "../assets/Icons/MW_NSF_Logo.svg";
import HM_Close from "../assets/Icons/MW_HM_Close.svg";
import HM_Open from "../assets/Icons/MW_HM_Open.svg";

export default function Navbar() {
  const { mobileNav, setMobileNav, pathName } = useAppContext();
  const noFixed = pathName === "/multipaper";
  const mint = pathName === "/mint";
  const navStyleFixed =
    "absolute w-full top-0 mw:px-8 px-5 mwSmallDesktop:px-0 mw:pt-8 pt-5 short:pt-0 z-[55] mt-5 mwTiny:pt-0 mwTiny:px-3 mw-normal";
  const navNotFixed =
    "w-full top-0 mw:px-8 px-5  mwSmallDesktop:px-0 mw:pt-8 pt-5 z-30 mt-5 mwTiny:pt-0 mwTiny:px-3 mw-normal";
  const linkStyle =
    "bg-mw-offwhite border-solid border-[0.25px] border-mw-black rounded-[9px] text-mw-black text-xl mwSmallDesktop:text-[14px] items-center relative h-11 p-1 tracking-wider flex justify-center opacity-100 mw-normal";
  const deactivatedLinkStyle =
    "border-solid border-[0.25px] border-mw-white rounded-[9px] text-mw-offwhite text-xl mwSmallDesktop:text-[14px] items-center relative h-11 p-1 tracking-wider flex justify-center opacity-100 pointer-events-none cursor-default mw-normal";
  return (
    <div className={`${noFixed ? navNotFixed : navStyleFixed} ${mint && !mobileNav && "mwTiny:bg-black/40 mwTiny:mt-0"}`}>
      <div className="z-30 flex h-full border-b border-black items-center justify-center mx-auto mwSmallDesktop:mx-0 border-opacity-0">
        <div className="flex-grow mwSmallDesktop:mr-10">
          <Link
            to="/"
            onClick={() => setMobileNav(false)}
            className={`flex z-30 ${!mobileNav ? "" : "short:hidden"} ${
              pathName === "/" && "shorter:hidden annoyinglyShort:hidden"
            }`}
          >
            <img
              src={logo}
              alt="tim"
              className="flex w-full h-full max-h-[100px] max-w-[350px] ml-0 mwSmallDesktop:ml-8 mwMobile:ml-8 mw4k:ml-8 mwTiny:ml-3 mwTiny:w-[250px] mwTiny:mb-2 z-30"
            />
          </Link>
        </div>
        <div className="hidden mw:flex mw4k:flex mwSmallDesktop:flex items-center">
          <div className="space-x-mw mwSmallDesktop:space-x-3 flex z-30">
            <Link
              to="/multipaper"
              className={`${linkStyle} w-[165px] mwSmallDesktop:w-[110px] -mr-[7px] mwSmallDesktop:mr-0`}
            >
              Multipaper
            </Link>
            <Link
              to="/portal"
              className={`${linkStyle} w-[105px] mwSmallDesktop:w-[70px] -mr-[8px] mwSmallDesktop:mr-0`}
            >
              Portal
            </Link>
            <a
              href="https://h4x.gg/pages/walkers"
              target="_blank"
              rel="noreferrer"
              className={`${linkStyle} w-[140px] mwSmallDesktop:w-[100px] -mr-[10px] mwSmallDesktop:mr-0`}
            >
              Multishop
            </a>
            <Link
              to="/mint"
              className={`${linkStyle} w-[80px] mwSmallDesktop:w-[55px] -mr-[10px] mwSmallDesktop:mr-0`}
            >
              Mint
            </Link>
              <Link
                to="/anomaly"
                className={`${linkStyle} w-[125px] mwSmallDesktop:w-[90px] -mr-[10px] mwSmallDesktop:mr-0`}
              >
                Anomaly
              </Link>
            <div className="relative group flex flex-col items-center justify-center">
              <Link
                to="/gallery"
                className={`${deactivatedLinkStyle} w-[100px] mwSmallDesktop:w-[75px] -mr-[8px] mwSmallDesktop:mr-0`}
              >
                Gallery
              </Link>
              <p
                className={`absolute hidden group-hover:flex -mr-[8px] mwSmallDesktop:mr-0 text-[0.5rem] mwSmallDesktop:text-12px text-center ${
                  pathName === "/portal" && "text-mw-offwhite"
                }`}
              >
                COMING SOON
              </p>
            </div>
            <Link
              to="/datacenter"
              className={`${linkStyle} w-[175px] mwSmallDesktop:w-[130px] mr-[9px] mwSmallDesktop:mr-0`}
            >
              Data Centre
            </Link>
          </div>
          <a
            href="https://twitter.com/MultiversalWalk/"
            target="_blank"
            rel="noreferrer"
            className="flex-shrink-0 opacity-100 z-30 w-[60px] h-[60px] mwSmallDesktop:w-[40px] mwSmallDesktop:h-[40px] mw4k:w-[150px] mw4k:h-[150px] mr-[7px] mwSmallDesktop:mr-0"
          >
            <img src={twitter} alt="twitter logo" />
          </a>
          <div className="relative group flex flex-col items-center justify-center">
            <a
              href="https://www.instagram.com/multiversal_walkers/"
              target="_blank"
              rel="noreferrer"
              className="flex-shrink-0 opacity-25 z-30 pointer-events-none cursor-default w-[60px] h-[60px] mwSmallDesktop:w-[40px] mwSmallDesktop:h-[40px] mw4k:w-[150px] mw4k:h-[150px] mr-[8px] mwSmallDesktop:mr-0"
            >
              <img src={discord} alt="Discord logo" />
            </a>
            <p className="absolute hidden group-hover:flex top-[100%] w-[150px] rounded-[10px] bg-mw-offwhite border-[0.25px] border-mw-black border-solid opacity-75 p-1 mw-normal text-[1rem] text-center">
              Keep an eye on our twitter!
            </p>
          </div>
          <a
            href="https://medium.com/@MultiversalWalkers"
            target="_blank"
            rel="noreferrer"
            className="flex-shrink-0 z-30 w-[60px] h-[60px] mwSmallDesktop:w-[40px] mwSmallDesktop:h-[40px] mw4k:w-[150px] mw4k:h-[150px] mr-[5px] mwSmallDesktop:mr-0"
          >
            <img src={medium} alt="Medium Logo" />
          </a>
          <a
            href="https://www.instagram.com/multiversal_walkers/"
            target="_blank"
            rel="noreferrer"
            className="flex-shrink-0 opacity-100 z-30 w-[60px] h-[60px] mwSmallDesktop:w-[40px] mwSmallDesktop:h-[40px] mw4k:w-[150px] mw4k:h-[150px] mr-[6px] mwSmallDesktop:mr-0"
          >
            <img src={instagram} alt="Instagram logo" />
          </a>
          <a
            href="https://twitter.com/notsofungibles"
            target="_blank"
            rel="noreferrer"
            className="flex-shrink-0 opacity-100 z-30 w-[60px] h-[60px] mwSmallDesktop:w-[40px] mwSmallDesktop:h-[40px] mw4k:w-[150px] mw4k:h-[150px] mr-[3px] mwSmallDesktop:mr-0"
          >
            <img src={notSoFungibles} alt="notSoFungibles Logo" />
          </a>
        </div>
        <div className="mw:hidden mw4k:hidden z-30 mwSmallDesktop:hidden">
          <button type="button" onClick={() => setMobileNav((prev) => !prev)}>
            {mobileNav ? (
              <img
                src={HM_Close}
                alt="Close Modal"
                className="w-[60px] h-[60px]"
              />
            ) : (
              <img
                src={HM_Open}
                alt="Open Modal"
                className="w-[60px] h-[60px]"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
